import preguntas from "./questions"
import { useState, useEffect } from "react"
import parse from "html-react-parser";
import { Link } from "react-router-dom";


function Quiz() {

    const [preguntaActual, setPreguntaActual] = useState(-1);
    const [puntuacion, setPuntuacion] = useState(0);
    const [isFinished, setIsFinished] = useState(false);
    const [showAnswer, setshowAnswer] = useState(false);
    const [disableOptions, setDisableOptions] = useState(false);

    const handleAnswerSubmit = (isCorrect, e) => {
        //Desactivamos botones
        setDisableOptions(true)

        //Añadir estilos de ok o ko
        e.target.classList.add(isCorrect ? "correct" : "no-correct");

        //Mostramos respuesta
        setshowAnswer(true);
        document.getElementById('respuesta')?.scrollIntoView({ behavior: 'smooth' });

        //Añadir puntos
        if (isCorrect) {
            setPuntuacion(puntuacion + 1)
        }

    }

    const handleNextQuestion = () => {
        // e.target.classList.(isCorrect ? "correct" : "no-correct");

        if (preguntaActual === preguntas.length - 1) {
            setIsFinished(true);
        } else {
            setPreguntaActual(preguntaActual + 1);
            setshowAnswer(false)
            document.getElementById('quiz')?.scrollIntoView({ behavior: 'smooth' });
            setDisableOptions(false)
        }
    }

    const handleStartGameButton = () => {
        setPreguntaActual(0);
    }


    if (isFinished)
        return (
            <main className="bg-indigo-500 w-full  min-h-screen h-full">


                <nav className={'navbar'}>
                    <svg width="150" height="150"
                        xmlns="http://www.w3.org/2000/svg">
                        <Link to="/" className="nav-link"><image href='/logo.svg' height="150" width="130" /></Link>
                    </svg>
                </nav>

                <div className="container  max-w-md mx-auto text-center text-white">
                    <img alt="arbol fondo" src="https://www.airco2.earth/arbol_fondo.svg"></img>
                    <p className="text-xl pt-5">
                        {" "}
                        Has acertado {puntuacion} pregunta{puntuacion != 1 ? "s" : ""} de un total de {preguntas.length}.{" "}
                    </p>
                    <p className="text-xl">
                        ¡Gracias por jugar con nosotros!
                    </p>

                    {/* TODO: form */}
                    <div className="text-center">
                        <button className="inline btn bg-white text-indigo-500  hover:bg-indigo-600  font-bold mt-5 mr-1"
                            onClick={() => (window.location.href = "/quizdata")}>
                            Participa en el sorteo
                        </button>

                        {/* <button className="inline btn bg-white text-indigo-500  hover:bg-indigo-600  font-bold mt-5" onClick={() => (window.location.href = "/quiz")}>
                            {" "}
                            Volver a jugar
                        </button> */}
                    </div>
                </div>
            </main>
        );


    if (preguntaActual == -1) {
        return (<main className="bg-indigo-500 w-full  min-h-screen h-full pb-5">


            <nav className={'navbar'}>
                <svg width="150" height="150"
                    xmlns="http://www.w3.org/2000/svg">
                    <Link to="/" className="nav-link"><image href='/logo.svg' height="150" width="130" /></Link>
                </svg>
            </nav>


            <div className="container  max-w-md mx-auto text-center text-white">
                <h1 className="text-3xl font-bold mb-5">Bienvenido al quiz medioambiental de airCO2</h1>
                <h2 className="text-xl mb-3"><u>Instrucciones del juego</u></h2>
                <p className="text-justify  mb-2 ">Responde las preguntas pulsando sobre la opción que consideres correcta.</p>
                <p className="text-justify mb-2"> Una vez pulses tu respuesta se registrará, así que piensa muy bien qué vas a marcar antes de hacerlo. </p>
                <p className="text-justify mb-2">Al seleccionar la pregunta se indicará la respuesta correcta y podrás seguir jugando.</p>
                <p className="text-justify"> Cuando termines podrás completar un pequeño formulario para participar en nuestro sorteo.</p>

                <button className="btn bg-white text-indigo-500  hover:bg-indigo-600  font-bold mt-5" onClick={() => handleStartGameButton()}>Empezar a jugar</button>

            </div>



        </main>)
    }

    return <main id="quiz" className="bg-indigo-500 w-full  min-h-screen h-full">


        <nav className={'navbar'}>
            <svg width="150" height="150"
                xmlns="http://www.w3.org/2000/svg">
                <Link to="/" className="nav-link"><image href='/logo.svg' height="150" width="130" /></Link>
            </svg>
        </nav>

        <div className="container  max-w-md mx-auto text-center text-white pb-5">

            <div className="numero-pregunta">
                <span className="text-xs"> Pregunta {preguntaActual + 1} de  {preguntas.length}</span>
            </div>
            <div className="titulo-pregunta mb-3"><h3 className="text-xl">{preguntas[preguntaActual].titulo} </h3></div>
            <div className="imagen-pregunta mb-4"> <img alt="imagen pregunta" className="img-question" src={preguntas[preguntaActual].imagen}></img></div>
            <div className="opciones-preguntas">
                {preguntas[preguntaActual].opciones.map((respuesta, index) => (
                    <button className="btn-answer" key={respuesta.textoRespuesta} disabled={disableOptions} onClick={(e) => handleAnswerSubmit(respuesta.isCorrect, e)}> {respuesta.textoRespuesta} </button>
                ))}
            </div>
            <div id="respuesta" className="respuesta mt-4">
                {
                    showAnswer && parse(preguntas[preguntaActual].respuesta)

                }
            </div>

            {showAnswer && <button className="btn bg-white text-indigo-500  hover:bg-indigo-600  font-bold mt-4" onClick={() => handleNextQuestion()}>{preguntaActual + 1 < preguntas.length ? "Siguiente pregunta" : "Ver mis resultados"} </button>}
        </div>
    </main >

}

export default Quiz;