import React from 'react'
import cartel from '../../../images/landing-cartel.webp'
import logo from '../../../images/logo2.png';
import BloqueFormBosque from './BloqueFormBosque';
import { useTranslation } from 'react-i18next';
import { IdiomContext } from "../../../context/createcontext";
import { useContext } from 'react'
export default function InicioBosque() {
  const [lng, updateLng] = useContext(IdiomContext)
  const [t, i18n] = useTranslation("global");
  const claseT = 'p-2 rounded-lg flex items-center w-full h-full'
  const color = 'RGBA(135, 135, 135, 0.5)'
  return (
    <div className='landing-cont h-full w-full'>
      <div className='fondo-landing flex flex-wrap items-end justify-center'>

        <BloqueFormBosque></BloqueFormBosque>


        <div className=' hidden md:flex md:grow  container fondo-cartel w-full md:w-1/2 '>
          <img style={{ height: '400px' }} src={cartel} alt="Bosque corporativo" />

        </div>


        <div className='container flex  w-full md:w-1/2 '>

          <div className='container m-4 text-airco2-general flex  justify-center md:justify-end  mb-5' >
            <div className=' p-5 rounded-lg ' style={{ backgroundColor: 'rgba(141, 212, 189, 0.85)' }}>
              <h2>  <span className='font-bold text-white text-3xl'>airCO2<span className='pl-3 text-airco2-general  '>{t("landingEventos.acerca")}</span></span>
                <span className='font-bold text-airco2-genera text-3xl mb-1'>{t("landingEventos.empresasNetZero")}. </span></h2>
              <p className='font-semibold text-white text-2xl mt-1'>{t("landingEventos.ayudamos")} </p>
              <p className='font-semibold text-white text-2xl'>{t("landingEventos.maxVisibilidad")} </p>
              <div className=" mt-6 mb-6 flex " data-aos="fade-in" data-aos-delay="100">
                <a href="/register" className="btn bg-gris text-airco2-filter-content  whitespace-nowrap w-full lg:w-1/2 " >
                  <span style={{ color: 'white', textDecoration: 'none' }}>{t("landingEventos.registrateYa")}</span>

                </a>


              </div>
              <div className='flex justify-center  md:justify-end'>
                <img className='item' height="200" width="130" src={logo} alt="Tu empresa" />
              </div>





            </div>
          </div>


        </div>

        <div className=' flex grow md:hidden container fondo-cartel w-full md:w-1/2 justify-center '>
          <img style={{ height: '400px' }} src={cartel} alt="Tu empresa" />
        </div>

      </div>
    </div>








  )
}
