import React from 'react'
import image from '../../../images/marketplace.png'
import { useContext } from "react";
import { IdiomContext } from "../../../context/createcontext"
import { useTranslation } from 'react-i18next';

export default function BannerForestal() {
  const [lng, updateLng] = useContext(IdiomContext)
  const [t, i18n] = useTranslation("global");
  return (
    <section className='max-w-6xl mx-auto px-4 sm:px-6 pb-5 text-airco2-general-grey'>
      <div className="relative  bg-gray-50 rounded-lg p-6 flex items-center shadow-lg flex-col md:flex-row">
        <div className="bg-gray-50" data-aos="flip-left">
          <h2 className="h3 font-semibold mb-8"> {t("newHome.conocePropuesta")}</h2>
          <p className='text-md'>{t("newHome.conocePropuestaBody")}</p>
          <div className='mt-4'>
            <a
              href="/forestal"
              className=" btn inline-block text-airco2-general  bg-green-semitr font-medium py-2 px-4 rounded shadow transition duration-150 linkNav"
            >
              <span>{t("newHome.goCompensacion")} </span>
            </a>
          </div>

        </div>
        <div className="relative ml-6 img-txt-hidden w-full" >
          <img
            src={image}
            alt="airCO2 marketplace"
            className="success-slide img-forest mt-4"
          />
        </div>
      </div>
    </section>


  )
}
