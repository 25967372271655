import axios from "axios";
import React, { useContext, useEffect, useRef, useState } from "react";
import { Modal, ModalBody, ModalHeader, ModalTitle, Spinner, ModalFooter } from "react-bootstrap";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from "react-bootstrap/Tooltip";
import { useTranslation } from 'react-i18next';
import { IdiomContext } from "../../../context/createcontext";
import info from "../../../images/info.gif";
import UserService from "../../../services/UserService";
import ModalMatchColumns from "./columnMappingForm";
import GoogleDistance from "./GetOriginDestinationDistance"
import * as XLSX from 'xlsx';
import { useDropzone } from 'react-dropzone';
import { useCallback } from "react";
import Papa from 'papaparse';

function ExcelComponent({ inputData, idCalculation, questBlock, addAnswer1, showSendButton, isButtonDisabled }) {
  const [lng, updateLng] = useContext(IdiomContext)
  const [t, i18n] = useTranslation("global");

  const processStates = [{ index: 1, title: t("footPrint.modalExcelBreadcums1") },
  { index: 2, title: t("footPrint.modalExcelBreadcums2") },
  { index: 3, title: t("footPrint.modalExcelBreadcums3") },
  { index: 4, title: t("footPrint.modalExcelBreadcums4") }];
  const [processStateIndex, setProcessStateIndex] = useState(1);
  const [workbook, setWorkbook] = useState(null);
  const [sheetNames, setSheetNames] = useState([]);
  const [selectedSheet, setSelectedSheet] = useState('');
  const [fileName, setFileName] = useState('');
  const [previewData, setPreviewData] = useState([]);
  const [excelData, setExcelData] = useState([]);
  const [columnMappings, setColumnMappings] = useState([]);
  const [showModalConfirmColumns, setShowModalConfirmColumns] = useState(false);
  const [formatData, setFormatData] = useState([]);
  const [submitBtnState, setSubmitBtnState] = useState(false);

  const [inputObjectSedes, setInputObjectSedes] = useState([]);
  const [inputObjectCombustible, setInputObjectCombustible] = useState([]);

  const [inputObjectGdo, setInputObjectGdo] = useState([]);

  const dataCopyRef = useRef(null);
  const [formatDataCopy, setFormatDataCopy] = useState([]);
  const [dobleSelect, setDobleSelect] = useState(null);
  const [inputTitles, setInputTitles] = useState([]);
  const [inputTitlesRequired, setInputTitlesRequired] = useState([]);
  const [inputOptions, setInputOptions] = useState([]);
  const [editVisible, setEditVisible] = useState({ row: null, col: null });
  const [modalDeleteRow, setModalDeleteRow] = useState({ view: false, indexRow: null, spinner: false })
  const [hasNullIds, setHasNullIds] = useState(true);
  const [modalCorrectErrors, setModalCorrectErrors] = useState(false)
  const [savedAnswers, setSavedAnswers] = useState({
    data: [],
    isFetching: false,
  });

  const [changeSedes, setChangeSedes] = useState();
  const [changeConcept, setChangeConcept] = useState();
  const [changeConceptDesplegable, setChangeConceptDesplegable] = useState();
  const [changeGdo, setChangeGdo] = useState();
  const [changeQuantity, setChangeQuantity] = useState();
  const [changeString, setChangeString] = useState();
  const [changeRegistrationDate, setChangeRegistrationDate] = useState();
  const [changeRoundTrip, setChangeRoundTrip] = useState();
  const [googleDistanceModal, setGoogleDistanceModal] = useState({ view: false, idInput: null });
  const [excelError, setExcelError] = useState('');
  const [excelTextAreaError, setExcelTextAreaError] = useState('');

  const [cellStatusIcon, setCellStatusIcon] = useState({ok:
    <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-circle-check" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="#8dd4bd" fill="none" strokeLinecap="round" strokeLinejoin="round">
      <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
      <path d="M12 12m-9 0a9 9 0 1 0 18 0a9 9 0 1 0 -18 0" />
      <path d="M9 12l2 2l4 -4" />
    </svg>,
    ko: <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-alert-triangle" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="#ff2825" fill="none" strokeLinecap="round" strokeLinejoin="round">
    <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
    <path d="M12 9v4" />
    <path d="M10.363 3.591l-8.106 13.534a1.914 1.914 0 0 0 1.636 2.871h16.214a1.914 1.914 0 0 0 1.636 -2.87l-8.106 -13.536a1.914 1.914 0 0 0 -3.274 0z" />
    <path d="M12 16h.01" />
  </svg>
  });

  const cellEditIcon = <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-edit" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="#f19b7f" fill="none" strokeLinecap="round" strokeLinejoin="round">
    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
    <path d="M7 7h-1a2 2 0 0 0 -2 2v9a2 2 0 0 0 2 2h9a2 2 0 0 0 2 -2v-1" />
    <path d="M20.385 6.585a2.1 2.1 0 0 0 -2.97 -2.97l-8.415 8.385v3h3l8.385 -8.415z" />
    <path d="M16 5l3 3" />
  </svg>;

  //Reset del componente
  const handleResetExcelComponent = () => {
    setFormatData([]);
    setColumnMappings([]);
    setExcelData([])
    setProcessStateIndex(1);
    setSelectedSheet('');
    setSheetNames([]);
    setWorkbook(null);
    setFileName('');
    setPreviewData([]);
  }

  useEffect(() => {
    setSubmitBtnState(false)
  }, [isButtonDisabled]);


  //Reset del componente al cambiar de pregunta
  useEffect(() => {
    handleResetExcelComponent();
  }, [inputData])


  //Cada cambio de pregunta guarda los arrays con las sedes,combustibles y gdos de BBDD; Crea el array con los valores de los titulos de las cabeceras y los inputOptions corresponidente a cada pregunta para poder hacer las comprobaciones
  useEffect(() => {
    if (inputData != undefined && inputData != true) {
      setInputObjectSedes(inputData.filter(inputObj => inputObj.input.inputOptions === "SEDES").flatMap(filteredObj => filteredObj.input.options));
      setInputObjectCombustible(inputData.filter(inputObj => inputObj.input.inputOptions == "CONCEPT").flatMap(filteredObj => filteredObj.input.options));
      // setInputObjectCantidad(inputData.filter(inputObj => inputObj.input.inputOptions=="QUANTITY").flatMap(filteredObj => filteredObj.input.inputType));
      setInputObjectGdo(inputData.filter(inputObj => inputObj.input.inputOptions == "GDO").flatMap(filteredObj => filteredObj.input.options));

      let basicTitles = inputData.map(inputObj => inputObj.input).map(input => input.title);

      setInputTitlesRequired(inputData.filter(inputObj => inputObj.required === true).map(inputObj => inputObj.input).map(input => input.title))

      let inputOptions = inputData.map(inputObj => inputObj.input).map(input => input.inputOptions);

      let inputConceptHasCombustible = inputData.filter(inputObj => inputObj.input.inputOptions == "CONCEPT").flatMap(filteredObj => filteredObj.input.options).map(option => option.combustible);

      if (inputConceptHasCombustible[0] == undefined) {
        setInputTitles(basicTitles);
        setInputOptions(inputOptions);
        setDobleSelect(false)
      } else {
        basicTitles.push(inputConceptHasCombustible[0][0].subtitle);
        setInputTitles(basicTitles);
        inputOptions.push("CONCEPT");
        setInputOptions(inputOptions);
        setDobleSelect(true)
      }

    }
  }, [inputData])


  const handlePaste = (event) => {
    const clipboardData = event.clipboardData || window.clipboardData;
    const pastedData = clipboardData.getData('text');
    // Check if text contains tabs
    if (pastedData.includes('\t')) {
      handlePasteExcel(pastedData);
    } else if (pastedData.includes(',') || pastedData.includes(';')) {
      handlePasteCsv(pastedData);
    } else {
      setExcelTextAreaError(t("footPrint.modalExcelErrorTextArea"));
      setTimeout(() => {
        setExcelTextAreaError('');
      }, 3000);
    }
  };


  //Evento que crea un array con la data pegada en el textarea
  const handlePasteCsv = (pastedData) => {
    const rows = pastedData.split('\n');

    const parsedData = rows.map(row => {
      const columns = [];
      let currentColumn = '';
      let inQuotes = false;

      for (let i = 0; i < row.length; i++) {
        const char = row[i];

        if (char === '"') {
          inQuotes = !inQuotes;
        } else if (char === ',' && inQuotes) {
          currentColumn += '.';
        } else if (char === ',') {
          columns.push(currentColumn);
          currentColumn = '';
        } else {
          currentColumn += char;
        }
      }

      columns.push(currentColumn);

      return columns;
    });

    setExcelData(parsedData);
    setShowModalConfirmColumns(true);
    setProcessStateIndex(3);
    setExcelError('');
    setExcelTextAreaError('');
  };


  //Evento que crea un array con la data pegada en el textarea
  const handlePasteExcel = (pastedData) => {
    const rows = pastedData.split('\n').map(row => {
      const columns = row.split('\t');
      return columns.map(column => column.replace(/,/g, '.'));
    });


    setExcelData(rows);
    setShowModalConfirmColumns(true);
    setProcessStateIndex(3);
    setExcelError('');
    setExcelTextAreaError('');
  };



  //Evento para crear  un array con la data del Excel 
  const handleFile = (file) => {

    if (file.type !== 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' && file.type !== 'application/vnd.ms-excel' && file.type !== 'text/csv') {
      setExcelError(t("exceltransactions.checkFile"));
      setTimeout(() => {
        setExcelError('');
      }, 3000);
      return;
    } else if (file.type == 'text/csv') {
      Papa.parse(file, {
        complete: (result) => {
          setExcelData(result.data);
          setShowModalConfirmColumns(true);
          setProcessStateIndex(3);
          setExcelError('');
          setExcelTextAreaError('')
        },
        error: (error) => {
          console.error('Error parsing CSV file:' + error);
          setExcelData([]);
        },
      });
    } else {
      setFileName(file.name)
      const reader = new FileReader();
      reader.onload = (e) => {
        const data = new Uint8Array(e.target.result);
        const wb = XLSX.read(data, { type: 'array' });
        setWorkbook(wb);
        setSheetNames(wb.SheetNames);
        if (wb.SheetNames.length == 1) {
          setSelectedSheet(wb.SheetNames[0]);
          loadSheetData(wb, wb.SheetNames[0], true);
        } else {
          setProcessStateIndex(2);
        }
      };
      reader.readAsArrayBuffer(file);
    }
  };

  const loadSheetData = (workbook, sheetName, confirmedSheet) => {
    const worksheet = workbook.Sheets[sheetName];
    const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });

    // Process the data to handle empty cells
    const processedData = jsonData.map(row => {
      const maxLength = Math.max(...jsonData.map(r => r.length));
      return Array.from({ length: maxLength }, (_, i) => row[i] !== undefined ? row[i] : "");
    });

    if (confirmedSheet) {
      setExcelData(processedData);
      setShowModalConfirmColumns(true);
      setProcessStateIndex(3);
      setExcelError('');
      setExcelTextAreaError('');
    } else {
      setPreviewData(processedData);
      setExcelError('');
      setExcelTextAreaError('');
    }

  };

  const handleSheetChange = (event) => {
    const sheetName = event.target.value;
    setSelectedSheet(sheetName);
    loadSheetData(workbook, sheetName, false);
  };

  const handleConfirmSheet = () => {
    loadSheetData(workbook, selectedSheet, true);
  };

  const onDrop = useCallback((acceptedFiles) => {
    const file = acceptedFiles[0];
    handleFile(file);
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop
  });


  //Función que recoge el match de cabeceras desde componente del modal y construye un array con el orden correspondiente o resetea a 0 el componente
  const handleColumnMappingSubmit = (mappings) => {

    if (mappings.length > 0) {
      setColumnMappings(mappings)
      // setMappings(mappings.map(item => item.name));
    } else {
      handleResetExcelComponent();
    }
    setShowModalConfirmColumns(false);

  };


  //Transformar la data de excel 
  const dataFormater = () => {
    if (excelData.length === 0) {
      return [];
    }

    let formattedData = excelData.slice(1).map((row) => {

      let formattedRow = Array(inputTitles.length);
      for (let i = 0; i < columnMappings.length; i++) {
        let obj = columnMappings[i];
        if (obj && typeof obj.index !== undefined) {
          formattedRow[i] = { name: row[obj.index], id: null };
        } else {
          formattedRow[i] = { name: "", id: null };
        }
      }
      return formattedRow;
    });

    //Mostrar tabla final para corregir y guardar datos
    setProcessStateIndex(4);
    return formattedData;
  };



  //Seteo del array con cabeceras correctas ordenadas, 
  useEffect(() => {
    //Array original usado para la tabla
    setFormatData(dataFormater())

    //Array con el mismo valor que el original que se usa para hacer la deep copy que sirve para editar cada celda si está mal
    setFormatDataCopy(dataFormater())
  }, [columnMappings])


  //Función que cambia todas las sedes con el mismo nombre al valor correcto escogido y guarda su id

  const handleApplyAllSedes = (nameSede) => {
    let indexColumnSedes = inputOptions.indexOf("SEDES");
    let copiaArray = [...formatData];

    const trimmedNameSede = String(nameSede)?.trim();

    copiaArray.forEach((element, index) => {
      const sedeName = element[indexColumnSedes]?.name;

      if (typeof sedeName !== 'undefined' && String(sedeName)?.trim() === trimmedNameSede) {
        element[indexColumnSedes] = { name: changeSedes.name, id: changeSedes.id };
      }
    });

    setFormatData(copiaArray);
    setChangeSedes();

    let selects = document.querySelectorAll('#selectSedes');
    selects.forEach((select) => {
      select.value = '';
    });
  }






  const handleApplySedes = (rowIndex, colIndex, id) => {

    if (changeSedes != undefined) {
      let copiaArray = [...formatData];
      copiaArray[rowIndex][colIndex] = { name: changeSedes.name, id: id };

      setFormatData(copiaArray);
    }
    setChangeSedes();
  };


  //Función que cambia todos valores del tipo CONCEPT con el mismo nombre al valor correcto escogido y guarda su id
  const handleApplyAllConcept = (nameConcept) => {

    let indexColumnConcept = inputOptions.indexOf("CONCEPT");
    let copiaArray = [...formatData];
    const trimmedNameConcept = String(nameConcept)?.trim();
    copiaArray.forEach(element => {
      const conceptName = element[indexColumnConcept]?.name;
      if (typeof conceptName !== 'undefined' && String(conceptName)?.trim() === trimmedNameConcept) {
        element[indexColumnConcept] = { name: changeConcept.name, id: changeConcept.id };
      }

    });

    setFormatData(copiaArray);
    setChangeConcept();

    let selects = document.querySelectorAll('#selectConcept');
    selects.forEach((select) => {
      select.value = '';
    });
  }

  //Función que cambia uno en concreto del tipo CONCEPT

  const handleApplySingleConcept = (rowIndex, colIndex) => {
    if (changeConcept && changeConcept.name) {
      let copiaArray = [...formatData];
      copiaArray[rowIndex][colIndex] = { name: changeConcept.name, id: changeConcept.id };

      setFormatData(copiaArray);
      setChangeConcept({});
    }
  };





  //Función que cambia todos valores del DOBLE DESPLEGABLE con el mismo nombre al valor correcto escogido y guarda su id

  const handleApplyAllConceptDesplegable = (nameConcept, namePadre) => {


    let indexColumnConcept = inputOptions.lastIndexOf("CONCEPT");
    let copiaArray = [...formatData];
    let indexColConceptPadre = inputOptions.indexOf("CONCEPT");
    const trimmedNameConcept = String(nameConcept)?.trim();
    const trimmedNamePadre = String(namePadre)?.trim();

    copiaArray.forEach(element => {
      const conceptName = element[indexColumnConcept]?.name;
      const conceptPadreName = element[indexColConceptPadre]?.name;

      if (typeof conceptName !== 'undefined' && String(conceptName)?.trim() === trimmedNameConcept) {
        if (element[indexColumnConcept].id === null || element[indexColumnConcept].id !== changeConceptDesplegable.id) {
          if (conceptPadreName?.trim() === trimmedNamePadre) {
            element[indexColumnConcept] = { name: changeConceptDesplegable.name, id: changeConceptDesplegable.id };
          }
        }
      }
    });

    setFormatData(copiaArray);
    setChangeConceptDesplegable();

    let selects = document.querySelectorAll('#selectConceptDesplegable');
    selects.forEach((select) => {
      select.value = '';
    });
  };


  // Función que cambia una celda cocnreta del DOBLE DESPLEGABLE

  const handleApplySingleConceptDesplegable = (rowIndex, colIndex, newValue, namePadre) => {
    let copiaArray = [...formatData];
    let indexColumnConcept = colIndex;
    let indexColConceptPadre = inputOptions.indexOf("CONCEPT");

    if (rowIndex < copiaArray.length && indexColumnConcept < copiaArray[rowIndex].length) {

      if (copiaArray[rowIndex][indexColConceptPadre].name === namePadre) {

        copiaArray[rowIndex][indexColumnConcept] = { name: newValue.name, id: newValue.id };
      }
    }

    setFormatData(copiaArray);
    setChangeConceptDesplegable();

    let selects = document.querySelectorAll('#selectConceptDesplegable');
    selects.forEach((select) => {
      select.value = '';
    });
  };



  //Función que cambia todos valores de GDO con el mismo nombre al valor correcto escogido y guarda su id
  const handleApplyAllGdo = (nameConcept) => {

    let indexColumnGdo = inputOptions.indexOf("GDO");
    let copiaArray = [...formatData];
    const trimmedNameConcept = String(nameConcept)?.trim();
    copiaArray.forEach(element => {
      const gdoName = element[indexColumnGdo]?.name;
      if (typeof gdoName !== 'undefined' && String(gdoName)?.trim() === trimmedNameConcept) {
        element[indexColumnGdo] = { name: changeGdo.name, id: changeGdo.id };
      }
    });

    setFormatData(copiaArray);
    setChangeGdo();

    let selects = document.querySelectorAll('#selectGdo');
    selects.forEach((select) => {
      select.value = '';
    });
  }

  const handleApplySingleGdo = (rowIndex, colIndex) => {

    if (changeGdo !== undefined && changeGdo.name !== undefined) {
      let copiaArray = [...formatData];

      copiaArray[rowIndex][colIndex] = {
        name: changeGdo.name,
        id: changeGdo.id
      };

      setFormatData(copiaArray);
      setChangeGdo();
    }
  };

  //Funcion para diferenciar los campos de cantidades 
  const handleQuantityCase = (rowIndex, colIndex, value, type) => {
    let quantityStatus = true;

    if (Number(value.name) <= 0 || isNaN(Number(value.name))) {
      // dataCopyRef.current[rowIndex][colIndex].id = `${type.toLowerCase()}-distance-input`;
      quantityStatus = false;
    } else {
      dataCopyRef.current[rowIndex][colIndex].id = `${type.toLowerCase()}-distance-input`;
    }

    return quantityStatus;
  };

  //Función que cambia la cantidad de una celda concreta
  const handleApplyQuantity = (rowIndex, colIndex) => {

    let copiaArray = [...formatData];
    copiaArray[rowIndex][colIndex] = { name: changeQuantity.name, id: changeQuantity.id }

    setFormatData(copiaArray);
    setChangeQuantity();
  }

  //Función que cambie el valor de todos los valores iguales a otro que pongas en el input 
  const handleApplyQuantityAll = (nameConcept, rowIndex, colIndex) => {

    if (changeQuantity != undefined) {
      let copiaArray = [...formatData];
      const trimmedNameConcept = String(nameConcept)?.trim();
      copiaArray.forEach(row => {
        row.forEach((cell, colI) => {
          const cellName = String(cell.name)?.trim();
          if (colI === colIndex && cellName === trimmedNameConcept) {
            cell.name = changeQuantity.name;
            cell.id = changeQuantity.id;
          }
        });
      });

      setFormatData(copiaArray);
    }
    setChangeString({});
  };

  //Función que cambie el valor de  una celda concreta de string a otro que pongas en el input 
  const handleApplyStrings = (rowIndex, colIndex, id) => {

    if (changeString != undefined) {
      let copiaArray = [...formatData];
      copiaArray[rowIndex][colIndex] = { name: changeString.name, id: id }
      setFormatData(copiaArray);
    }
    setChangeString();
  }

  const handleChangeString = (name, id) => {
    setChangeString({ name, id });
  };

  //Función que cambie el valor de todos los valores iguales a otro que pongas en el input 
  const handleApplyStringsToAll = (nameConcept, targetId) => {
    if (changeString !== undefined) {
      let copiaArray = [...formatData];
      const trimmedNameConcept = String(nameConcept)?.trim();

      copiaArray.forEach(row => {
        row.forEach(cell => {

          const cellName = String(cell.name)?.trim();

          if (cell.id === targetId && cellName === trimmedNameConcept) {
            cell.name = changeString.name;
            cell.id = changeString.id;
          }

        });
      });

      setFormatData(copiaArray);
    }

    setChangeString({});
  };


  //Función que cambia la fecha de una celda concreta
  const handleApplyRegistrationDate = (rowIndex, colIndex) => {

    if (changeRegistrationDate !== undefined && changeRegistrationDate.name !== undefined) {
      let copiaArray = [...formatData];

      copiaArray[rowIndex][colIndex] = {
        name: changeRegistrationDate.name,
        id: changeRegistrationDate.id
      };

      setFormatData(copiaArray);
      setChangeRegistrationDate();
    }
  };

  //Función que cambia la fecha de todas
  const handleApplyRegistrationDateToAll = (nameConcept) => {
    if (changeRegistrationDate && changeRegistrationDate.name) {
      let copiaArray = [...formatData];
      let changesMade = false;
      const trimmedNameConcept = String(nameConcept)?.trim();
      copiaArray.forEach((row, rowIndex) => {
        row.forEach((cell, colIndex) => {
          const cellName = String(cell.name)?.trim();

          if (cellName === trimmedNameConcept) {
            cell.name = changeRegistrationDate.name;
            cell.id = changeRegistrationDate.id;
            changesMade = true;
          }
        });
      });

      if (changesMade) {
        setFormatData(copiaArray);
        setChangeRegistrationDate({});
      }
    }
  };


  //Función que cambia la cantidad de todas las celdas de ida y vuelta
  const handleApplyRoundTrip = (roundTrip) => {
    let indexColumnRoundTrip = inputOptions.indexOf("ROUND_TRIP");
    let copiaArray = [...formatData];

    const trimmedRoundTrip = String(roundTrip)?.trim();

    copiaArray.forEach(element => {
      const roundTripName = element[indexColumnRoundTrip]?.name;
      if (typeof roundTripName === 'string' && roundTripName?.trim() === trimmedRoundTrip) {
        element[indexColumnRoundTrip] = {
          name: changeRoundTrip === undefined ? false : changeRoundTrip.name,
          id: changeRoundTrip === undefined ? "roundtripOK" : changeRoundTrip.id
        };
      }
    });

    setFormatData(copiaArray);
    setChangeRoundTrip();
  }


  //Función que cambia la cantidad de una celda concreta
  const handleApplySingleRoundTrip = (rowIndex, colIndex) => {

    if (changeRoundTrip !== undefined && changeRoundTrip.name !== undefined) {
      let copiaArray = [...formatData];

      copiaArray[rowIndex][colIndex] = {
        name: changeRoundTrip.name,
        id: changeRoundTrip.id
      };

      setFormatData(copiaArray);
      setChangeRoundTrip();
    }
  };


  //Duplicado del array original que se usa para modificar las celdas erroneas desde la tabla
  useEffect(() => {
    dataCopyRef.current = formatDataCopy.map(subArray => subArray.map(item => ({ ...item })));
  }, [formatDataCopy])


  const comprobaciones = (value, rowIndex, colIndex, desplegableOriginArray, paso, notAllCellsOk) => {
    //value: valor de la celda 
    //desplegableOriginArray: array que guarda el valor el array para el doble deplegable
    //paso: array inicializado a [] al que se añade longitud si el CONCEPT es correcto para poder comprobar el valor correspondiente del doble desplegable si es que lo hay
    //allCellsOk: booleano que indica si la fila tiene todas las columnas con valores correctos

    switch (inputOptions[colIndex]) {

      case "SEDES":

        let sedeStatus = false;

        inputObjectSedes.map((sedeBBDD) => {
          if (sedeBBDD.name === (value.name) && value.name !== "") {
            dataCopyRef.current[rowIndex][colIndex].id = sedeBBDD.id;
            sedeStatus = true;
          }
        })

        return (
          <div key={colIndex + "comprobacion"}>

            {(!notAllCellsOk && (editVisible.row !== rowIndex || editVisible.col !== colIndex)) && (
              <div className="flex justify-center gap-1 items-center">
                <span>{value.name}</span>
                <span onClick={() => setEditVisible({ row: rowIndex, col: colIndex })}>{cellEditIcon}</span>
              </div>
            )}

            {notAllCellsOk && sedeStatus === true && (editVisible.row !== rowIndex || editVisible.col !== colIndex) && (
              <div className="flex justify-center gap-1 items-center">
                <span>{value.name}</span>
                <span>{cellStatusIcon.ok}</span>
                <span onClick={() => setEditVisible({ row: rowIndex, col: colIndex })}>{cellEditIcon}</span>
              </div>
            )}

            {(sedeStatus === false || (editVisible.row === rowIndex && editVisible.col === colIndex)) && (
              <div className='text-sm flex flex-col items-center justify-center mb-2'>
                <div className='text-sm flex justify-center gap-1 items-center' onClick={() => setEditVisible({ row: null, col: null })}>
                  <span>{value.name}</span>
                  <span>{cellStatusIcon.ko}</span>
                </div>
                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                    handleApplyAllSedes(value.name);
                    setEditVisible({ row: null, col: null })
                  }}
                  className="flex flex-col"
                >
                  <select
                    id="selectSedes"
                    className='text-xs my-1'
                    style={{ maxWidth: "220px" }}
                    onChange={(e) => setChangeSedes(JSON.parse(e.target.value))}
                    required
                  >
                    <option value="">{t("footPrint.seleccionaSede")}</option>
                    {inputObjectSedes.map((sedeBBDD) => (
                      <option key={sedeBBDD.id} value={JSON.stringify({ name: sedeBBDD.name, id: sedeBBDD.id })}>
                        {sedeBBDD.name}
                      </option>
                    ))}
                  </select>
                  <button
                    type="button"
                    onClick={(e) => {
                      e.preventDefault();
                      handleApplySedes(rowIndex, colIndex, 'selectSedes');
                      setEditVisible({ row: null, col: null })
                    }}
                    className="text-xs btn-sm btn-green mt-2"
                  >
                    {t("footPrint.guardarCambio")}
                  </button>
                  <button type="submit" className="text-xs btn-sm btn-green mt-2">
                    {t("footPrint.aplicarTodos")}
                  </button>
                </form>
              </div>
            )}
          </div>
        );

      case "QUANTITY0":
        let quantity0Status = true;
        if (Number(value.name) <= 0 || isNaN(Number(value.name))) {
          quantity0Status = false;
        }
        dataCopyRef.current[rowIndex][colIndex].id = "qtyOk";
        return (
          <div key={colIndex + "comprobacion"}>
            {(!notAllCellsOk && (editVisible.row !== rowIndex || editVisible.col !== colIndex)) && (
              <div className="flex justify-center gap-1 items-center">
                <span>{value.name}</span>
                <span onClick={() => setEditVisible({ row: rowIndex, col: colIndex })}>{cellEditIcon}</span>
              </div>
            )}
            {(notAllCellsOk && quantity0Status === true && (editVisible.row !== rowIndex || editVisible.col !== colIndex)) && (
              <div className="flex justify-center gap-1 items-center">
                <span>{value.name}</span>
                <span>{cellStatusIcon.ok}</span>
                <span onClick={() => setEditVisible({ row: rowIndex, col: colIndex })}>{cellEditIcon}</span>
              </div>
            )}
            {(quantity0Status === false || (editVisible.row === rowIndex && editVisible.col === colIndex)) && (
              <div className='text-sm flex flex-col items-center justify-center mb-2'>
                <div className='text-sm flex justify-center gap-1 items-center' onClick={() => setEditVisible({ row: null, col: null })}>
                  <span>{value.name}</span>
                  <span>{cellStatusIcon.ko}</span>
                </div>                
                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                    handleApplyQuantity(rowIndex, colIndex);
                    setEditVisible({ row: null, col: null });
                  }}
                  className="flex flex-col"
                >
                  <div className="flex flex-row items-center">
                    <input
                      type="number"
                      id="qtyOk"
                      min="0.01"
                      step="0.01"
                      className='text-sm w-auto my-1'
                      defaultValue={Number(value.name)}
                      style={{ maxWidth: "140px" }}
                      onChange={(e) => setChangeQuantity({ name: e.target.value, id: "qtyOk" })}
                    />
                  </div>
                  <button type="submit" className="btn-sm text-xs btn-green mt-2">{t("footPrint.guardarCambio")}</button>
                  <button type="button" onClick={(e) => { e.preventDefault(); handleApplyQuantityAll(value.name, 'qtyOk'); setEditVisible({ row: null, col: null }) }} className="text-xs btn-sm btn-green mt-2">{t("footPrint.aplicarTodos")}</button>
                </form>
              </div>
            )}
          </div>
        );

      case "QUANTITY":
      case "QUANTITY2":
      case "QUANTITY3":
      case "QUANTITY4":
      case "QUANTITY5":
        let quantityStatus = handleQuantityCase(rowIndex, colIndex, value, inputOptions[colIndex]);

        return (
          <div key={colIndex + "comprobacion"}>

            {(!notAllCellsOk && (editVisible.row !== rowIndex || editVisible.col !== colIndex)) && (
              <div className="flex justify-center gap-1 items-center">
                <span>{value.name}</span>
                <span onClick={() => setEditVisible({ row: rowIndex, col: colIndex })}>{cellEditIcon}</span>
              </div>
            )}

            {notAllCellsOk && quantityStatus === true && (editVisible.row !== rowIndex || editVisible.col !== colIndex) && (
              <div className="flex justify-center gap-1 items-center">
                <span>{value.name}</span>
                <span>{cellStatusIcon.ok}</span>
                <span onClick={() => setEditVisible({ row: rowIndex, col: colIndex })}>{cellEditIcon}</span>
              </div>
            )}

            {(quantityStatus === false || (editVisible.row === rowIndex && editVisible.col === colIndex)) && (
              <div className='text-sm flex flex-col items-center justify-center mb-2'>
                <div className='text-sm flex justify-center gap-1 items-center' onClick={() => setEditVisible({ row: null, col: null })}>
                  <span>{t("footPrint.+Que")} 0</span>
                  <span>{cellStatusIcon.ko}</span>
                </div>
                <form onSubmit={(e) => { e.preventDefault(); handleApplyQuantity(rowIndex, colIndex); setEditVisible({ row: null, col: null }) }} className="flex flex-col">
                  <div className="flex flex-row items-center">
                    <input
                      type="number"
                      min="0.01"
                      step="0.01"
                      id={`${inputOptions[colIndex].toLowerCase()}-distance-input-${rowIndex}`}
                      className='text-sm w-auto my-1'
                      defaultValue={Number(value.name)}
                      style={{ maxWidth: "140px" }}
                      onChange={(e) => setChangeQuantity({ name: e.target.value, id: `${inputOptions[colIndex].toLowerCase()}-distance-input` })}
                      required
                    />
                    {inputTitles[colIndex] === 'Distancia recorrida (km)' && (
                      <OverlayTrigger
                        placement="top"
                        delay={{ show: 250, hide: 400 }}
                        overlay={renderTooltipDistancia}
                      >
                        <button type="button" onClick={() => handleModalDistanceCalculation(rowIndex)}>
                          <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-brand-google-maps" width="32" height="32" viewBox="0 0 24 24" strokeWidth="1.5" stroke="var(--airco2-orange-color)" fill="none" strokeLinecap="round" strokeLinejoin="round">
                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                            <path d="M12 9.5m-2.5 0a2.5 2.5 0 1 0 5 0a2.5 2.5 0 1 0 -5 0" />
                            <path d="M6.428 12.494l7.314 -9.252" />
                            <path d="M10.002 7.935l-2.937 -2.545" />
                            <path d="M17.693 6.593l-8.336 9.979" />
                            <path d="M17.591 6.376c.472 .907 .715 1.914 .709 2.935a7.263 7.263 0 0 1 -.72 3.18a19.085 19.085 0 0 1 -2.089 3c-.784 .933 -1.49 1.93 -2.11 2.98c-.314 .62 -.568 1.27 -.757 1.938c-.121 .36 -.277 .591 -.622 .591c-.315 0 -.463 -.136 -.626 -.593a10.595 10.595 0 0 0 -.779 -1.978a18.18 18.18 0 0 0 -1.423 -2.091c-.877 -1.184 -2.179 -2.535 -2.853 -4.071a7.077 7.077 0 0 1 -.621 -2.967a6.226 6.226 0 0 1 1.476 -4.055a6.25 6.25 0 0 1 4.811 -2.245a6.462 6.462 0 0 1 1.918 .284a6.255 6.255 0 0 1 3.686 3.092z" />
                          </svg>
                        </button>
                      </OverlayTrigger>
                    )}
                  </div>
                  <button type="submit" className="text-xs btn-sm btn-green mt-2">
                    {t("footPrint.guardarCambio")}
                  </button>
                  <button type="button" onClick={(e) => { e.preventDefault(); handleApplyQuantityAll(value.name, rowIndex, colIndex); setEditVisible({ row: null, col: null }) }} className="text-xs btn-sm btn-green mt-2">{t("footPrint.aplicarTodos")}</button>
                </form>
              </div>
            )}
          </div>
        );
      case "CONCEPT":

        if (paso.length == 0) {

          let combustibleStatus = false;
          inputObjectCombustible.map((combustibleBBDD) => {
            if (combustibleBBDD.name === (value.name) && value.name !== "") {
              dataCopyRef.current[rowIndex][colIndex].id = combustibleBBDD.id;
              combustibleStatus = true;
            }
          });

          desplegableOriginArray.push(...inputObjectCombustible.filter((combustibleBBDD) => {
            return (combustibleBBDD.name === (value.name))
          }))

          paso.push(true);

          return (
            <div key={colIndex + "comprobacion"}>
              {!notAllCellsOk && (editVisible.row !== rowIndex || editVisible.col !== colIndex) && (
                <div className="flex justify-center gap-1 items-center">
                  <span>{value.name}</span>
                  <span onClick={() => setEditVisible({ row: rowIndex, col: colIndex })}>{cellEditIcon}</span>
                </div>
              )}
              {notAllCellsOk && combustibleStatus && (editVisible.row !== rowIndex || editVisible.col !== colIndex) && (
                <div className="flex justify-center gap-1 items-center">
                  <span>{value.name}</span>
                  <span>{cellStatusIcon.ok}</span>
                  <span onClick={() => setEditVisible({ row: rowIndex, col: colIndex })}>{cellEditIcon}</span>
                </div>
              )}
              {(!combustibleStatus || (editVisible.row === rowIndex && editVisible.col === colIndex)) && (
                <div className='text-sm flex flex-col items-center justify-center mb-2'>
                  <div className='text-sm flex justify-center gap-1 items-center' onClick={() => setEditVisible({ row: null, col: null })}>
                    <span>{value.name}</span>
                    <span>{cellStatusIcon.ko}</span>
                  </div>  
                  <form
                    onSubmit={(e) => {
                      e.preventDefault();
                      handleApplyAllConcept(value.name);
                      setEditVisible({ row: null, col: null });
                    }}
                    className="flex flex-col"
                  >
                    <select
                      id="selectConcept"
                      className='text-xs my-1'
                      style={{ maxWidth: "220px" }}
                      onChange={(e) => setChangeConcept(JSON.parse(e.target.value))}
                      required
                    >
                      <option value="">{t("footPrint.seleccionaUn")} {inputTitles[colIndex]}{t("footPrint.correcto")}</option>
                      {inputObjectCombustible.map((combustibleBBDD) => (
                        <option key={combustibleBBDD.id} value={JSON.stringify({ name: combustibleBBDD.name, id: combustibleBBDD.id })}>
                          {combustibleBBDD.name}
                        </option>
                      ))}
                    </select>
                    <button type="button" onClick={(e) => {
                      e.preventDefault();
                      handleApplySingleConcept(rowIndex, colIndex);
                      setEditVisible({ row: null, col: null });
                    }} className="text-xs btn-sm btn-green mt-2">{t("footPrint.guardarCambio")}</button>
                    <button type="submit" className="text-xs btn-sm btn-green mt-2">{t("footPrint.aplicarTodos")}</button>
                  </form>
                </div>
              )}
            </div>
          );

        } else {

          if (desplegableOriginArray.length > 0) {

            let desplegableStatus = false;
            desplegableOriginArray[0].combustible.map((subItem) => {

              if (subItem.name === (value.name) && value.name !== "") {
                dataCopyRef.current[rowIndex][colIndex].id = subItem.id;
                desplegableStatus = true;
              }
            })

            return (
              <div key={colIndex + "comprobacion"}>
                {!notAllCellsOk && (editVisible.row !== rowIndex || editVisible.col !== colIndex) && (
                  <div className="flex justify-center gap-1 items-center">
                    <span>{value.name}</span>
                    <span onClick={() => setEditVisible({ row: rowIndex, col: colIndex })}>{cellEditIcon}</span>
                  </div>
                )}
                {notAllCellsOk && desplegableStatus && (editVisible.row !== rowIndex || editVisible.col !== colIndex) && (
                  <div className="flex justify-center gap-1 items-center">
                    <span>{value.name}</span>
                    <span>{cellStatusIcon.ok}</span>
                    <span onClick={() => setEditVisible({ row: rowIndex, col: colIndex })}>{cellEditIcon}</span>
                  </div>
                )}
                {(!desplegableStatus || (editVisible.row === rowIndex && editVisible.col === colIndex)) && (
                  <div className='text-sm flex flex-col items-center justify-center mb-2'>
                    <div className='text-sm flex justify-center gap-1 items-center' onClick={() => setEditVisible({ row: null, col: null })}>
                      <span>{value.name}</span>
                      <span>{cellStatusIcon.ko}</span>
                    </div>  
                    <form
                      onSubmit={(e) => {
                        e.preventDefault();
                        handleApplySingleConceptDesplegable(rowIndex, colIndex, changeConceptDesplegable, desplegableOriginArray[0].name);

                        setEditVisible({ row: null, col: null });

                      }}
                      className="flex flex-col"
                    >
                      <select
                        id="selectConceptDesplegable"
                        className='text-xs my-1'
                        style={{ maxWidth: "220px" }}
                        onChange={(e) => {
                          const selectedValue = JSON.parse(e.target.value);
                          setChangeConceptDesplegable(selectedValue);

                        }}
                        required
                      >
                        <option value="">{t("footPrint.seleccionaUn")}{inputTitles[colIndex]} {t("footPrint.correcto")}</option>
                        {desplegableOriginArray[0].combustible.map((subItem) => (
                          <option key={subItem.id} value={JSON.stringify({ name: subItem.name, id: subItem.id })}>
                            {subItem.name}
                          </option>
                        ))}
                      </select>
                      <button type="submit" className="text-xs btn-sm btn-green mt-2"> {t("footPrint.guardarCambio")}</button>
                      {value.name &&
                        <button type="button" className="text-xs btn-sm btn-green mt-2"
                          onClick={(e) => {
                            e.preventDefault();
                            handleApplyAllConceptDesplegable(value.name, desplegableOriginArray[0].name);
                            setEditVisible({ row: null, col: null });
                          }}
                        >{t("footPrint.aplicarTodos")}</button>
                      }

                    </form>
                  </div>
                )}
              </div>
            );

          } else {
            let indexConcept = inputOptions.indexOf("CONCEPT");
            return (
              <div className="flex flex-col">
                <div>{value.name} ⚠️</div>
                <div>{t("footPrint.seleccionaUn")} {inputTitles[indexConcept]} {t("footPrint.correcto")}</div>
              </div>)
          }
        }

      case "GDO":
        let gdoStatus = false;

        inputObjectGdo.map((gdoOpt) => {
          if (gdoOpt.name === (value.name) && value.name !== "") {
            dataCopyRef.current[rowIndex][colIndex].id = gdoOpt.id;
            gdoStatus = true;
          }
        })

        return (
          <div key={colIndex + "comprobacion"}>

            {!notAllCellsOk && (editVisible.row !== rowIndex || editVisible.col !== colIndex) && (
              <div className="flex justify-center gap-1 items-center">
                <span>{value.name}</span>
                <span onClick={() => setEditVisible({ row: rowIndex, col: colIndex })}>{cellEditIcon}</span>
              </div>
            )}
            {notAllCellsOk && gdoStatus && (editVisible.row !== rowIndex || editVisible.col !== colIndex) && (
              <div className="flex justify-center gap-1 items-center">
                <span>{value.name}</span>
                <span>{cellStatusIcon.ok}</span>
                <span onClick={() => setEditVisible({ row: rowIndex, col: colIndex })}>{cellEditIcon}</span>
              </div>
            )}
            {(!gdoStatus) || (editVisible.row === rowIndex && editVisible.col === colIndex) ? (
              <div className='text-sm flex flex-col items-center justify-center mb-2'>
                <div className='text-sm flex justify-center gap-1 items-center' onClick={() => setEditVisible({ row: null, col: null })}>
                  <span>{value.name}</span>
                  <span>{cellStatusIcon.ko}</span>
                </div>
                <form onSubmit={(e) => { e.preventDefault(); handleApplyAllGdo(value.name); setEditVisible({ row: null, col: null }) }} className="flex flex-col">
                  <select id="selectGdo" className='text-xs my-1' style={{ maxWidth: "220px" }} onChange={(e) => setChangeGdo(JSON.parse(e.target.value))} required>
                    <option value="">{t("footPrint.GDOcorrecta")}</option>
                    {inputObjectGdo.map((gdoOpt) => (
                      <option key={gdoOpt.id} value={JSON.stringify({ name: gdoOpt.name, id: gdoOpt.id })}>
                        {gdoOpt.name}
                      </option>
                    ))}
                  </select>
                  <button onClick={(e) => { e.preventDefault(); handleApplySingleGdo(rowIndex, colIndex); setEditVisible({ row: null, col: null }) }} type="button" className="text-xs btn-sm btn-green mt-2">{t("footPrint.guardarCambio")}</button>
                  <button type="submit" className="text-xs btn-sm btn-green mt-2">{t("footPrint.aplicarTodos")}</button>
                </form>
              </div>
            ) : ''}
          </div>
        );

      case "NAME":
        dataCopyRef.current[rowIndex][colIndex].id = "name";

        return (
          <div key={colIndex + "comprobacion"}>
            {((editVisible.row === null && editVisible.col === null) || (editVisible.row !== rowIndex || editVisible.col !== colIndex)) &&
              <div className="flex justify-center gap-1 items-center">
                <span>{value.name}</span>
                <span>{notAllCellsOk ? cellStatusIcon.ok : "" }</span>
                <span onClick={() => setEditVisible({ row: rowIndex, col: colIndex })}>{cellEditIcon}</span>
              </div>}
            {editVisible.row === rowIndex && editVisible.col === colIndex &&
              <div className='text-sm flex flex-col items-center justify-center mb-2'>
                <form className=" flex flex-col" onSubmit={(e) => { e.preventDefault(); handleApplyStringsToAll(value.name, 'name'); setEditVisible({ row: null, col: null }) }}>
                  <div className="flex justify-center gap-1 items-center" onClick={() => { setEditVisible({ row: null, col: null }) }}>
                    <span>{cellStatusIcon.ko}</span>
                  </div>  
                  <input
                    onChange={(e) => handleChangeString(e.target.value, "name")}
                    type="text" id="nameOk" defaultValue={value.name} />
                  <button onClick={(e) => { e.preventDefault(); handleApplyStrings(rowIndex, colIndex, 'name'); setEditVisible({ row: null, col: null }) }} type="button" className="text-xs btn-sm btn-green mt-2">{t("footPrint.guardarCambio")}</button>
                  <button type="submit" className="text-xs btn-sm btn-green mt-2">{t("footPrint.aplicarTodos")}</button>
                </form>
              </div>
            }
          </div>
        );

      case "UNIT":
        dataCopyRef.current[rowIndex][colIndex].id = "unit";

        return (
          <div key={colIndex + "comprobacion"}>
            {((editVisible.row === null && editVisible.col === null) || (editVisible.row !== rowIndex || editVisible.col !== colIndex)) &&
              <div className="flex justify-center gap-1 items-center">
                <span>{value.name}</span>
                <span>{notAllCellsOk ? cellStatusIcon.ok : "" }</span>
                <span onClick={() => setEditVisible({ row: rowIndex, col: colIndex })}>{cellEditIcon}</span>
              </div>}
            {editVisible.row === rowIndex && editVisible.col === colIndex &&
              <div className='text-sm flex flex-col items-center justify-center mb-2'>
                <form className=" flex flex-col" onSubmit={(e) => { e.preventDefault(); handleApplyStringsToAll(value.name, 'unit'); setEditVisible({ row: null, col: null }) }}>
                  <div className="flex justify-center gap-1 items-center" onClick={() => { setEditVisible({ row: null, col: null }) }}>
                    <span>{cellStatusIcon.ko}</span>
                  </div>  
                  <input
                    onChange={(e) => handleChangeString(e.target.value, "unit")}
                    type="text" id="unitOk" defaultValue={value.name} />
                  <button onClick={(e) => { e.preventDefault(); handleApplyStrings(rowIndex, colIndex, 'unit'); setEditVisible({ row: null, col: null }) }} type="button" className="text-xs btn-sm btn-green mt-2">{t("footPrint.guardarCambio")}</button>
                  <button type="submit" className="text-xs btn-sm btn-green mt-2">{t("footPrint.aplicarTodos")}</button>

                </form>
              </div>
            }
          </div>
        );

      case "REGISTRATION_DATE":
        let registrationDate = true;
        // dataCopyRef.current[rowIndex][colIndex].id = "registration_date";
        if (value.name === undefined || value.name === "" || value.name === "\r") {
          dataCopyRef.current[rowIndex][colIndex].id = "registration_date";
        } else if (!isNaN(Number(value.name))) {
          registrationDate = false;
        } else if (!value.name.includes("-") && !value.name.includes("/")) {
          registrationDate = false;
        } else {
          dataCopyRef.current[rowIndex][colIndex].id = "registration_date";
        }

        return (
          <div key={colIndex + "comprobacion"}>

            {(!notAllCellsOk && (editVisible.row !== rowIndex || editVisible.col !== colIndex)) && (
              <div className="flex justify-center gap-1 items-center">
                <span>{changeDateFormat(value.name)}</span>
                <span onClick={() => setEditVisible({ row: rowIndex, col: colIndex })}>{cellEditIcon}</span>
              </div>
            )}

            {notAllCellsOk && registrationDate && (editVisible.row !== rowIndex || editVisible.col !== colIndex) && (
              <div className="flex justify-center gap-1 items-center">
                <span>{changeDateFormat(value.name)}</span>
                <span>{cellStatusIcon.ok}</span>
                <span onClick={() => setEditVisible({ row: rowIndex, col: colIndex })}>{cellEditIcon}</span>
              </div>
            )}

            {(!registrationDate) || (editVisible.row === rowIndex && editVisible.col === colIndex) ? (
              <div className='text-sm flex flex-col items-center justify-center mb-2'>
                <div className='text-sm flex justify-center gap-1 items-center' onClick={() => setEditVisible({ row: null, col: null })}>
                  <span>{value.name}</span>
                  <span>{cellStatusIcon.ko}</span>
                </div>
                <form onSubmit={(e) => {
                  e.preventDefault();
                  handleApplyRegistrationDate(rowIndex, colIndex)
                  setEditVisible({ row: null, col: null });
                }} className="flex flex-col">
                  <input type="date"
                    onChange={(e) => setChangeRegistrationDate({ name: e.target.value, id: "registration_date" })}
                    className='text-sm w-auto my-1'
                    id="registration_date"
                    defaultValue={value.name} style={{ maxWidth: "140px" }}
                  />
                  <button type="submit" className="text-xs btn-sm btn-green mt-2">{t("footPrint.guardarCambio")}</button>
                  <button onClick={(e) => { e.preventDefault(); handleApplyRegistrationDateToAll(value.name); setEditVisible({ row: null, col: null }) }} type="button" className="text-xs btn-sm btn-green mt-2">{t("footPrint.aplicarTodos")}</button>
                </form>
              </div>
            ) : ''}
          </div>
        );

      case "OBSERVATIONS":
        dataCopyRef.current[rowIndex][colIndex].id = "observations";

        return (
          <div key={colIndex + "comprobacion"}>
            {((editVisible.row === null && editVisible.col === null) || (editVisible.row !== rowIndex || editVisible.col !== colIndex)) &&
              <div className="flex justify-center gap-1 items-center">
                <span>{value.name}</span>
                <span>{notAllCellsOk ? cellStatusIcon.ok : "" }</span>
                <span onClick={() => setEditVisible({ row: rowIndex, col: colIndex })}>{cellEditIcon}</span>
              </div>}
            {editVisible.row === rowIndex && editVisible.col === colIndex &&
              <div className='text-sm flex flex-col items-center justify-center mb-2'>
                <form className=" flex flex-col" onSubmit={(e) => { e.preventDefault(); handleApplyStringsToAll(value.name, 'observations'); setEditVisible({ row: null, col: null }) }}>
                  <div className="flex justify-center gap-1 items-center" onClick={() => { setEditVisible({ row: null, col: null }) }}>
                    <span>{cellStatusIcon.ko}</span>
                  </div>
                  <input
                    onChange={(e) => handleChangeString(e.target.value, "observations")}
                    type="text" id="observations" defaultValue={value.name} />
                  <button onClick={(e) => { e.preventDefault(); handleApplyStrings(rowIndex, colIndex, 'observations'); setEditVisible({ row: null, col: null }) }} type="button" className="text-xs btn-sm btn-green mt-2">{t("footPrint.guardarCambio")}</button>
                  <button type="submit" className="text-xs btn-sm btn-green mt-2">{t("footPrint.aplicarTodos")}</button>
                </form>
              </div>
            }
          </div>

        );
      case "ORIGIN":
        dataCopyRef.current[rowIndex][colIndex].id = "origin";
        return (
          <div key={colIndex + "comprobacion"}>
            {((editVisible.row === null && editVisible.col === null) || (editVisible.row !== rowIndex || editVisible.col !== colIndex)) &&
              <div className="flex justify-center gap-1 items-center">
                <span>{value.name}</span>
                <span>{notAllCellsOk ? cellStatusIcon.ok : "" }</span>
                <span onClick={() => setEditVisible({ row: rowIndex, col: colIndex })}>{cellEditIcon}</span>
              </div>}
            {editVisible.row === rowIndex && editVisible.col === colIndex &&
              <div className='text-sm flex flex-col items-center justify-center mb-2'>
                <form className=" flex flex-col" onSubmit={(e) => { e.preventDefault(); handleApplyStringsToAll(value.name, 'origin'); setEditVisible({ row: null, col: null }) }}>
                  <div className="flex justify-center gap-1 items-center"  onClick={() => { setEditVisible({ row: null, col: null }) }}>
                    <span>{cellStatusIcon.ko}</span>
                  </div>
                  <input
                    onChange={(e) => handleChangeString(e.target.value, "origin")}
                    type="text" id="origin" defaultValue={value.name} />
                  <button onClick={(e) => { e.preventDefault(); handleApplyStrings(rowIndex, colIndex, 'origin'); setEditVisible({ row: null, col: null }) }} type="button" className="text-xs btn-sm btn-green mt-2">{t("footPrint.guardarCambio")}</button>
                  <button type="submit" className="text-xs btn-sm btn-green mt-2">{t("footPrint.aplicarTodos")}</button>
                </form>
              </div>
            }
          </div>
        );

      case "DESTINATION":
        dataCopyRef.current[rowIndex][colIndex].id = "destination";
        return (
          <div key={colIndex + "comprobacion"}>
            {((editVisible.row === null && editVisible.col === null) || (editVisible.row !== rowIndex || editVisible.col !== colIndex)) &&
              <div className="flex justify-center gap-1 items-center">
                <span>{value.name}</span>
                <span>{notAllCellsOk ? cellStatusIcon.ok : "" }</span>
                <span onClick={() => setEditVisible({ row: rowIndex, col: colIndex })}>{cellEditIcon}</span>
              </div>}
            {editVisible.row === rowIndex && editVisible.col === colIndex &&
              <div className='text-sm flex flex-col items-center justify-center mb-2'>
                <form className=" flex flex-col" onSubmit={(e) => { e.preventDefault(); handleApplyStringsToAll(value.name, 'destination'); setEditVisible({ row: null, col: null }) }}>
                  <div className="flex justify-center gap-1 items-center" onClick={() => { setEditVisible({ row: null, col: null }) }}>
                    <span>{cellStatusIcon.ko}</span>
                  </div>
                  <input
                    onChange={(e) => handleChangeString(e.target.value, "destination")}
                    type="text" id="destination" defaultValue={value.name} />
                  <button onClick={(e) => { e.preventDefault(); handleApplyStrings(rowIndex, colIndex, 'destination'); setEditVisible({ row: null, col: null }) }} type="button" className="text-xs btn-sm btn-green mt-2">{t("footPrint.guardarCambio")}</button>
                  <button type="submit" className="text-xs btn-sm btn-green mt-2">{t("footPrint.aplicarTodos")}</button>
                </form>
              </div>
            }
          </div>
        );

      case "IDENTIFIER":
        dataCopyRef.current[rowIndex][colIndex].id = "identifier";
        return (
          <div key={colIndex + "comprobacion"}>
            {((editVisible.row === null && editVisible.col === null) || (editVisible.row !== rowIndex || editVisible.col !== colIndex)) &&
              <div className="flex justify-center gap-1 items-center">
                <span>{value.name}</span>
                <span>{notAllCellsOk ? cellStatusIcon.ok : "" }</span>
                <span onClick={() => setEditVisible({ row: rowIndex, col: colIndex })}>{cellEditIcon}</span>
              </div>}
            {editVisible.row === rowIndex && editVisible.col === colIndex &&
              <div className='text-sm flex flex-col items-center justify-center mb-2'>
                <form className=" flex flex-col" onSubmit={(e) => { e.preventDefault(); handleApplyStringsToAll(value.name, 'identifier'); setEditVisible({ row: null, col: null }) }}>
                  <div className="flex justify-center gap-1 items-center"  onClick={() => { setEditVisible({ row: null, col: null }) }}>
                    <span>{cellStatusIcon.ko}</span>
                  </div>
                  <input
                    onChange={(e) => handleChangeString(e.target.value, "identifier")}
                    type="text" id="identifier" defaultValue={value.name} />
                  <button onClick={(e) => { e.preventDefault(); handleApplyStrings(rowIndex, colIndex, 'identifier'); setEditVisible({ row: null, col: null }) }} type="button" className="text-xs btn-sm btn-green mt-2">{t("footPrint.guardarCambio")}</button>
                  <button type="submit" className="text-xs btn-sm btn-green mt-2">{t("footPrint.aplicarTodos")}</button>
                </form>
              </div>
            }
          </div>
        );

      case "ROUND_TRIP":
        let roundtrip = true;

        if (value && typeof value.name === 'string') {

          switch (value.name.trim().toUpperCase()) {
            case "SI":
            case "SÍ":
            case "VERDADERO":
              value.name = true;
              break;
            case "NO":
            case "FALSO":
            case "":
              value.name = false;
              break;
          }
        }
        if (value.name === undefined) {
          value.name = false
        }
        if (value.name !== true && value.name !== false) {
          roundtrip = false;
        } else {
          dataCopyRef.current[rowIndex][colIndex].id = "roundtripOK";
        }

        return (
          <div key={colIndex + "comprobacion"}>

            {!notAllCellsOk && (editVisible.row !== rowIndex || editVisible.col !== colIndex) && (
              <div className="flex justify-center gap-1 items-center">
                <span>{value.name ? "Sí" : "No"}</span>
                <span onClick={() => setEditVisible({ row: rowIndex, col: colIndex })}>{cellEditIcon}</span>
              </div>
            )}

            {notAllCellsOk && roundtrip && (editVisible.row !== rowIndex || editVisible.col !== colIndex) && (
              <div className="flex justify-center gap-1 items-center">
                <span>{value.name ? "Sí" : "No"}</span>
                <span>{cellStatusIcon.ok}</span>
                <span onClick={() => setEditVisible({ row: rowIndex, col: colIndex })}>{cellEditIcon}</span>
              </div>
            )}

            {(!roundtrip || (editVisible.row === rowIndex && editVisible.col === colIndex)) && (
              <div className='text-sm flex flex-col items-center justify-center mb-2'>
                <div className='text-sm flex justify-center gap-1 items-center' onClick={() => setEditVisible({ row: null, col: null })}>
                  <span>{value.name}</span>
                  <span>{cellStatusIcon.ko}</span>
                </div>  
                <form onSubmit={(e) => { e.preventDefault(), handleApplyRoundTrip(value.name), setEditVisible({ row: null, col: null }) }} className="flex flex-col">
                  <select id="selectRoundTrip" className='text-xs my-1' style={{ maxWidth: "220px" }} onChange={(e) => setChangeRoundTrip(JSON.parse(e.target.value))} required>
                    <option value="">{t("footPrint.seleccionaIdaYvuelta")}</option>
                    <option value={JSON.stringify({ name: true, id: "roundtripOK" })}>Sí</option>
                    <option value={JSON.stringify({ name: false, id: "roundtripOK" })}>No</option>
                  </select>
                  <button onClick={(e) => { e.preventDefault(); handleApplySingleRoundTrip(rowIndex, colIndex); setEditVisible({ row: null, col: null }) }} type="button" className="text-xs btn-sm btn-green mt-2">{t("footPrint.guardarCambio")}</button>
                  <button type="submit" className="text-xs btn-sm btn-green mt-2">{t("footPrint.aplicarTodos")}</button>
                </form>
              </div>
            )
            }
          </div>
        );

    }
  }


  function changeDateFormat(date) {
    //pasar a string para que no rompa en el trim
    date = String(date);

    // Crea un objeto Date con la fecha proporcionada
    if (date !== undefined && date !== "" && date !== "\r") {
      date = date.trim();
      var originalDate;

      // Extrae los componentes del día, mes y año
      if (date.includes("/")) {
        var dateParts = date.split('/');
        var dayPart = parseInt(dateParts[0], 10);
        var monthPart = parseInt(dateParts[1], 10) - 1; // Restamos 1 al mes porque en JavaScript los meses van de 0 a 11
        var yearPart = parseInt(dateParts[2], 10);

        originalDate = new Date(yearPart, monthPart, dayPart);

      } else {
        originalDate = new Date(date);
      }
      // Obtiene los componentes de la fecha (año, mes y día)
      var year = originalDate.getFullYear();
      var month = ('0' + (originalDate.getMonth() + 1)).slice(-2); // Agrega un cero al mes si es necesario
      var day = ('0' + originalDate.getDate()).slice(-2); // Agrega un cero al día si es necesario

      // Construye la cadena en el formato deseado
      var formatedDate = year + '-' + month + '-' + day;

      return formatedDate;
    } else {
      return date;
    }
  }

  //UseEffect que guarda el mismo array pero con los IDs de los campos que están correctos después de que acabe el renderizado
  useEffect(() => {
    setFormatData(dataCopyRef.current);
  });


  //Borrado de una linea del array que forma la tabla con resultados previos a la importación
  const handleDeleteRow = (rowIndex) => {
    setModalDeleteRow({ view: false, indexRow: null, spinner: true })
    let copiaArray = [...formatData];
    copiaArray.splice(rowIndex, 1);
    setFormatDataCopy(copiaArray);
    setModalDeleteRow({ view: false, indexRow: null, spinner: false })
  }


  //Handle que hace la llamada para guardar las linea en BBDD o mostrar el modal si hay lineas incorrectas
  const handlerSubmitAnswers = e => {

    e.preventDefault();
    if (hasNullIds) {
      setModalCorrectErrors(true);
    } else {
      setSubmitBtnState(true);
      const countQuantity = inputOptions.reduce((acc, currentValue) => {
        if (currentValue === "QUANTITY") {
          return acc + 1;
        }
        return acc;
      }, 0);

      let sedesIndex = inputOptions.indexOf("SEDES");
      let conceptIndex = dobleSelect ? inputOptions.lastIndexOf("CONCEPT") : inputOptions.indexOf("CONCEPT");
      let quantity0Index = [inputOptions.indexOf("QUANTITY0")];
      let quantityIndex = [inputOptions.indexOf("QUANTITY")];
      let quantity2Index = [inputOptions.indexOf("QUANTITY2")];
      let quantity3Index = [inputOptions.indexOf("QUANTITY3")];
      let quantity4Index = [inputOptions.indexOf("QUANTITY4")];
      let quantity5Index = [inputOptions.indexOf("QUANTITY5")];

      let nameIndex = inputOptions.indexOf("NAME");
      let unitIndex = inputOptions.indexOf("UNIT");
      let gdoIndex = inputOptions.indexOf("GDO");

      let observationsIndex = [inputOptions.indexOf("OBSERVATIONS")];
      let registrationDateIndex = [inputOptions.indexOf("REGISTRATION_DATE")];
      let originIndex = [inputOptions.indexOf("ORIGIN")];
      let destinationIndex = [inputOptions.indexOf("DESTINATION")];
      let identifierIndex = [inputOptions.indexOf("IDENTIFIER")];
      let roundTripIndex = [inputOptions.indexOf("ROUND_TRIP")];

      if (countQuantity > 1) {
        quantityIndex.push(inputOptions.lastIndexOf("QUANTITY"))
      }



      let data = formatData.map(element => {

        return (
          {
            "question": parseInt(questBlock?.data.id, 10),
            "concept": conceptIndex !== -1 && element[conceptIndex] ? element[conceptIndex].id : null,
            "quantity0": quantity0Index !== -1 && element[quantity0Index] ? Number(element[quantity0Index].name) : null,
            "quantity": quantityIndex !== -1 && element[quantityIndex] ? Number(element[quantityIndex].name) : null,
            "quantity2": quantity2Index !== -1 && element[quantity2Index] ? Number(element[quantity2Index].name) : null,
            "quantity3": quantity3Index !== -1 && element[quantity3Index] ? Number(element[quantity3Index].name) : null,
            "quantity4": quantity4Index !== -1 && element[quantity4Index] ? Number(element[quantity4Index].name) : null,
            "quantity5": quantity5Index !== -1 && element[quantity5Index] ? Number(element[quantity5Index].name) : null,
            "organizationBuilding": sedesIndex !== -1 && element[sedesIndex] ? element[sedesIndex].id : null,
            "gdo": gdoIndex !== -1 && element[gdoIndex] ? element[gdoIndex].id : "NO",
            "name": nameIndex !== -1 && element[nameIndex] ? element[nameIndex].name : "",
            "unit": unitIndex !== -1 && element[unitIndex] ? element[unitIndex].name : "",
            "observations": observationsIndex !== -1 && element[observationsIndex] ? element[observationsIndex].name : "",
            "registration_date": registrationDateIndex !== -1 && element[registrationDateIndex] ? changeDateFormat(element[registrationDateIndex].name) : "",
            "origin": originIndex !== -1 && element[originIndex] ? element[originIndex].name : "",
            "destination": destinationIndex !== -1 && element[destinationIndex] ? element[destinationIndex].name : "",
            "identifier": identifierIndex !== -1 && element[identifierIndex] ? element[identifierIndex].name : "",
            "round_trip": roundTripIndex !== -1 && element[roundTripIndex] ? element[roundTripIndex].name : ""
          }
        )
      });


      const fetchSavedAnswer = async () => {
        const url = `${process.env.REACT_APP_CH_CALCULATION}/${idCalculation}/answers`;
        try {
          setSavedAnswers({ savedAnswers: savedAnswers.data, isFetching: true });
          const response = await axios.post(url, data, {
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              // 'Access-Control-Allow-Origin': '*',
              'Authorization': `Bearer ${UserService.getToken()}`
            }
          });
          setSavedAnswers({ data: response.data, isFetching: false });
          //Envio respuesta al padre para actualizar la tabla de resutlados
          addAnswer1(response.data);
          //Reset de todo el componente
          handleResetExcelComponent();

        } catch (exception) {
          setSavedAnswers({ data: [], isFetching: false });
        }
      }
      fetchSavedAnswer();
    }
  }


  //Seteo del estado hasNullIds con booleano para indicar si la tabla tiene todas las lineas correctas
  useEffect(() => {
    if (formatData.length > 0) {
      let hasNullId = false;
      for (const row of formatData) {
        if (row.some(obj => obj.id === null)) {
          hasNullId = true;
          break;
        }
      }
      setHasNullIds(hasNullId);
    }
  }, [formatData])



  const renderTooltipInfo = (props) => (
    <Tooltip id="toolTip-info" className="tooltip-grande" {...props}>
      <img src={info} alt="gif-info" />
    </Tooltip>
  );

  const handleModalDistanceCalculation = (rowIndex) => {
    setGoogleDistanceModal({ view: true, idInput: `quantity-distance-input-${rowIndex}` });
  }

  const renderTooltipDistancia = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      {t("footPrint.calcularDistancia")}
    </Tooltip>);

  const handleDistanceFromGoogle = (data) => {
    setChangeQuantity({ name: data.distance, id: "qtyOk" })
  }


  return (
    <div>
      <div className=" flex flex-wrap justify-start w-full gap-4 bg-white pt-2 pb-4 z-80" style={{ position: 'fixed', top: '65.5px' }}>
        {
          processStates.map((v, i) => {
            return (
              <div className="flex items-center gap-4">
                <div className={` text-lg ${processStateIndex == v.index ? "font-semibold" : ""} `}>
                  {v.title}
                </div>
                {!(i == processStates.length - 1) &&
                  <div>&gt;</div>}
              </div>
            )
          })
        }
      </div>
      <div className='m-2'>
        <Modal show={modalDeleteRow.view} className="xl-secondary-modal" animation={false} data-aos="fade-up" backdropClassName="xl-secondary-modal-backshadow">
          <div className="text-center mt-4 font-semibold h5">{t("footPrint.eliminarFila")}</div>
          <ModalBody>
            <p className="text-center text-sm">{t("footPrint.eliminarFilaSeguro")}</p>
          </ModalBody>
          <div className="flex justify-center space-x-4 mb-4">
            <button className="btn-sm btn-cancel text-xs" onClick={() => setModalDeleteRow({ view: false, indexRow: null, spinner: false })}>{t("footPrint.cancelar")}</button>
            <button className="btn-sm btn-green text-xs" onClick={() => handleDeleteRow(modalDeleteRow.indexRow)}>{modalDeleteRow.spinner ? <Spinner variant="light" animation="border" /> : "Eliminar"}</button>
          </div>
        </Modal>
        <Modal show={modalCorrectErrors} className="xl-secondary-modal" animation={false} data-aos="fade-up" backdropClassName="xl-secondary-modal-backshadow">
          <div className="flex justify-center items-center text-center mt-4 font-semibold gap-2 h5">
            <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-alert-circle" width="26" height="26" viewBox="0 0 24 24" strokeWidth="2" stroke="#fb608a" fill="none" strokeLinecap="round" strokeLinejoin="round">
              <path stroke="none" d="M0 0h24v24H0z" fill="none" />
              <path d="M3 12a9 9 0 1 0 18 0a9 9 0 0 0 -18 0" />
              <path d="M12 8v4" />
              <path d="M12 16h.01" />
            </svg>
            <span>{t("footPrint.error")} </span>
          </div>
          <ModalBody>
            <p className="text-center text-sm">{t("footPrint.corregir")}</p>
          </ModalBody>
          <div className="flex justify-center space-x-4 mb-4">
            <button className="btn-sm btn-green text-xs" onClick={() => setModalCorrectErrors(false)}>{t("footPrint.aceptar")}</button>
          </div>
        </Modal>
        <Modal show={googleDistanceModal.view} style={{ paddingTop: '145px' }} backdropClassName="xl-secondary-modal-backshadow">
          <div className="text-center mt-4 font-semibold h5">{t("footPrint.calcularDistancia")}</div>
          <ModalBody>
            <div>
              <GoogleDistance sendDataToParent={handleDistanceFromGoogle} idInput={googleDistanceModal.idInput} />
            </div>
          </ModalBody>
          <ModalFooter className="flex justify-center">
            <button className="btn-sm btn-cancel rounded-lg text-sm" onClick={() => setGoogleDistanceModal({ view: false, idInput: null })}>
              {t("footPrint.cerrar")}
            </button>
          </ModalFooter>
        </Modal>

        <div style={{ marginTop: '50px' }}>
          {processStateIndex == 1 &&
            <div className='flex flex-col my-2 text-sm' >
              <p className="mb-2">{t("footPrint.modalExcelCargaArchivo")}</p>
              <div {...getRootProps()} style={{ border: isDragActive ? '3px dashed #f19b7f' : '3px dashed #878787', padding: '20px', textAlign: 'center', height: "250px", borderRadius: "10px", alignContent: "center" }} className="mb-4">
                <input {...getInputProps({ accept: '.xlsx, .xls, .csv' })} />
                {
                  isDragActive ?
                    <p>{t("footPrint.sueltaArchivo")} ...</p> :
                    <div className="alig-center">
                      <div className=" flex justify-center items-center gap-4 mb-2 ">
                        <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-file-type-csv" width="52" height="52" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#878787" fill="none" strokeLinecap="round" strokeLinejoin="round">
                          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                          <path d="M14 3v4a1 1 0 0 0 1 1h4" />
                          <path d="M5 12v-7a2 2 0 0 1 2 -2h7l5 5v4" />
                          <path d="M7 16.5a1.5 1.5 0 0 0 -3 0v3a1.5 1.5 0 0 0 3 0" />
                          <path d="M10 20.25c0 .414 .336 .75 .75 .75h1.25a1 1 0 0 0 1 -1v-1a1 1 0 0 0 -1 -1h-1a1 1 0 0 1 -1 -1v-1a1 1 0 0 1 1 -1h1.25a.75 .75 0 0 1 .75 .75" />
                          <path d="M16 15l2 6l2 -6" />
                        </svg>
                        <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-file-type-xls" width="52" height="52" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#878787" fill="none" strokeLinecap="round" strokeLinejoin="round">
                          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                          <path d="M14 3v4a1 1 0 0 0 1 1h4" />
                          <path d="M5 12v-7a2 2 0 0 1 2 -2h7l5 5v4" />
                          <path d="M4 15l4 6" />
                          <path d="M4 21l4 -6" />
                          <path d="M17 20.25c0 .414 .336 .75 .75 .75h1.25a1 1 0 0 0 1 -1v-1a1 1 0 0 0 -1 -1h-1a1 1 0 0 1 -1 -1v-1a1 1 0 0 1 1 -1h1.25a.75 .75 0 0 1 .75 .75" />
                          <path d="M11 15v6h3" />
                        </svg>
                      </div>
                      <p style={{ color: '#878787' }} className="font-semibold">{t("footPrint.arrastraOclick")}</p>
                    </div>
                }
                {excelError && <p style={{ color: 'red' }}>{excelError}</p>}
              </div>

              <span className="flex gap-1 items-center my-2">
                <p>{t("footPrint.modalExcelPegaDatos")}</p>
                <OverlayTrigger
                  placement="top"
                  delay={{ show: 250, hide: 400 }}
                  overlay={renderTooltipInfo}
                  style={{ width: '650px', zIndex:"500" }}
                >
                  <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-info-circle" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="var(--airco2-orange-color)" fill="none" strokeLinecap="round" strokeLinejoin="round">
                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                    <path d="M3 12a9 9 0 1 0 18 0a9 9 0 0 0 -18 0" />
                    <path d="M12 9h.01" />
                    <path d="M11 12h1v4h1" />
                  </svg>
                </OverlayTrigger>

              </span>
              <p className="flex gap-1 items-center">
                <span className='font-semibold'>{t("footPrint.cabeceras")}: </span>
                <span>{inputTitles.join(", ")}</span>
              </p>
              <textarea readOnly onPaste={handlePaste} className='w-full mt-2' style={{ border: '2px solid #878787' }} placeholder="Pega aquí los datos incluyendo las cabeceras" />
              {excelTextAreaError && <p style={{ color: 'red', textAlign: 'center' }}>{excelTextAreaError}</p>}
            </div>}

          {processStateIndex == 2 &&
            <div>
              <div>
                {sheetNames.length > 1 &&
                  <div className="flex flex-col gap-2 justify-center items-center my-4 text-sm">
                    <label htmlFor="sheetSelect">{t("footPrint.modalExcelSelectHoja") + " " + fileName}: </label>
                    <select id="sheetSelect" className="text-sm" value={selectedSheet} onChange={handleSheetChange}>
                      <option value="">{t("footPrint.selecciona")}</option>
                      {sheetNames.map((name) => (
                        <option key={name} value={name}>
                          {name}
                        </option>
                      ))}
                    </select>
                    <button className="btn-sm btn-banner-o rounded-md mt-2" onClick={handleConfirmSheet}>{t("fpThird.continuar")}</button>
                  </div>
                }
              </div>
              {(selectedSheet && previewData.length > 0) &&
                <div>
                  <div className="flex justify-between text-sm mb-4 sec-text-neutro">
                    <span className="ml-2 ">{t("footPrint.modalExcelSelectHojaPreview")} <i>{selectedSheet}</i></span>
                    <span className="italic">{t("footPrint.mostrando") + (previewData.length > 10 ? "10" : previewData.length) + t("footPrint.de") + previewData.length + t("footPrint.lineas")}</span>
                  </div>
                  <div className='table-responsive'>
                    <table className="border-collapse border text-xs table-auto w-full">
                      <tbody className="divide-y text-center divide-gray-200">
                        {previewData.map((row, rowIndex) => {
                          if (rowIndex > 10) {
                            return;
                          } else if (rowIndex == 0) {
                            return (
                              <tr className="sec-green-background" key={rowIndex}>
                                {row.map((cell, cellIndex) => (
                                  <td className="border" key={cellIndex}>{cell}</td>
                                ))}
                              </tr>
                            )
                          } else {
                            return (
                              <tr key={rowIndex}>
                                {row.map((cell, cellIndex) => (
                                  <td className="border" key={cellIndex}>{cell}</td>
                                ))}
                              </tr>
                            )
                          }
                        }
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>}
            </div>
          }

          {(processStateIndex == 3 && showModalConfirmColumns) &&
            <ModalMatchColumns
              view={showModalConfirmColumns}
              userColumns={excelData[0]}
              predefinedColumns={inputTitles}
              predefinedColumnsRequired={inputTitlesRequired}
              onMappingSubmit={handleColumnMappingSubmit}
              userFirstLineData={excelData[1]}
            />
          }


          {processStateIndex == 4 &&
            <div>
              {formatData.length > 0 ?
                <div>
                  <div className=" overflow-x-auto w-full mt-2">
                    <table className="table-auto w-full border border-collapse text-sm">
                      <thead className="text-xs sec-neutro-background">
                        <tr>
                          {inputTitles.map((cell, cellIndex) => (
                            <th key={cellIndex} className="border p-2 text-center">{cell}</th>
                          ))}
                          <th className='text-center border'>{t("footPrint.borrar")}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {formatData.map((row, rowIndex) => {
                          let desplegableOriginArray = [];
                          let paso = [];
                          let notAllCellsOk = row.some(obj => obj.id === null);
                          return (
                            <tr key={rowIndex} className='text-center' style={{ backgroundColor: notAllCellsOk ? "#f800001f" : "" }}>
                              {row.map((value, colIndex) => {
                                if (colIndex == 0) {
                                  desplegableOriginArray = [];
                                  paso = [];
                                }
                                return (
                                  <td key={colIndex + "-" + rowIndex} className='border'>
                                    {comprobaciones(value, rowIndex, colIndex, desplegableOriginArray, paso, notAllCellsOk)}
                                  </td>
                                )
                              }
                              )}
                              <td className='border'>
                                <div className="flex flex-row justify-center items-center space-x-2">
                                  <button onClick={() => setModalDeleteRow({ view: true, indexRow: rowIndex, spinner: false })} tittle="Eliminar linea">
                                    <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-trash" width="26" height="26" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#f1a187" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                      <path d="M4 7l16 0" />
                                      <path d="M10 11l0 6" />
                                      <path d="M14 11l0 6" />
                                      <path d="M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12" />
                                      <path d="M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3" />
                                    </svg>
                                  </button>
                                </div>
                              </td>
                            </tr>
                          )
                        })}
                      </tbody>
                    </table>
                  </div>
                  <div className='flex justify-center w-full'>
                    <button type="button" style={{ width: '250px' }} onClick={handlerSubmitAnswers} className={`btn-sm btn-banner-o mt-4 ${!showSendButton ? "hidden" : ""} `} disabled={submitBtnState || formatData.length == 0 && true} >
                      {submitBtnState ? <Spinner animation="border" variant="light" size="sm" /> : t("footPrint.Guardar")}
                    </button>
                  </div>
                </div>
                :
                <div className="flex flex-col text-sm justify-center text-center">
                  <p>{t("footPrint.modalExcelNoData")}</p>
                  <div><butotn className="btn-sm btn-green mt-2" type="button" onClick={handleResetExcelComponent}>{t("fpThird.principio")}</butotn>
                  </div>
                </div>
              }
          </div>}
        </div>
      </div>
    </div>
  );
}

export default ExcelComponent;