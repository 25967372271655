import React from 'react'
import { Link } from 'react-router-dom';
import { useState, useContext, useEffect } from 'react';
import Banner from '../../components/utils/Banner';
import { IdiomContext } from "../../context/createcontext"
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import logoairco2 from '../../images/byAirco2verde.png'
import { getColor, getLogo, getFont, getName, getUrl } from '../../services/FunctionsDataWhiteLabel';
import { Helmet } from 'react-helmet';


export default function SignupExternal() {
  const [lng, updateLng] = useContext(IdiomContext)
  const [t, i18n] = useTranslation("global");
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  let param = queryParams.get('param');

  if (param) {

    localStorage.setItem('param', param);
  }

  let storedParam = localStorage.getItem('param');




  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [lastName, setLastName] = useState("");
  const [password, setPassword] = useState("");
  const [userType, setUserType] = useState("");
  const [companyName, setcompanyName] = useState("");
  const [phone, setPhone] = useState("");
  const [originData, setOriginData] = useState("Formulario");
  const [provincia, setProvincia] = useState("");
  const [whiteLabel, setWhiteLabel] = useState("");
  const [idUser, setIdUser] = useState("");

  const [disableButton, setDisableButton] = useState(false);
  const [passwordMessage, setPasswordMessage] = useState("");
  const [passwordError, setPasswordError] = useState({
    password: '',
    confirmPassword: ''
  })
  const [errorMessage, setErrorMessage] = useState({
    type: '',
    message: '',

  });
  const [input, setInput] = useState({
    password: '',
    confirmPassword: ''
  });
  const onInputPasswordChange = e => {
    const { name, value } = e.target;
    setInput(prev => ({
      ...prev,
      [name]: value
    }));
    validateInput(e);
  }

  const updatewhiteLabel = (param) => {
    if (param !== '') {
      setUserType('Representante empresarial');
      setWhiteLabel(param)
    } else {
      setUserType('');
      setWhiteLabel('')
    }
  };



  useEffect(() => {
    updatewhiteLabel(storedParam);
  }, [storedParam]);



  const validateInput = e => {

    let { name, value } = e.target;


    setPasswordError(prev => {
      const stateObj = { ...prev, [name]: "" };

      switch (name) {

        case "password":
          if (!value) {
            stateObj[name] = t("auth.passwordEmpty");
          } else if (value.length < 8) {
            stateObj[name] = t("auth.passwordLength");
          } else if (input.confirmPassword && value !== input.confirmPassword) {
            stateObj[name] = t("auth.passwordNOtMatch");
          } else {
            stateObj[name] = input.confirmPassword ? "" : passwordError.confirmPassword;
          }
          break;

        case "confirmPassword":
          if (!value) {
            stateObj[name] = t("auth.passwordRepeatEmpty");
          } else if (input.password && value !== input.password) {
            stateObj[name] = t("auth.passwordNOtMatch");
          } else {
            stateObj[name] = "";
            setPassword(input.password)
          }
          break;

        default:
          break;
      }
      setPasswordMessage(stateObj[name]);


      return stateObj;
    });
  }
  const data = { name: name, surname: lastName, password: password, email: email, userType: userType, companyName: companyName, phone: phone, originData: originData, coinscrap: false, province: provincia, whiteLabel: whiteLabel }




  const register = (event) => {
    event.preventDefault();
    setDisableButton(true);
    if (email === "" || name === "" || lastName === "" || password === "" || userType === "" || phone === "") {
      setErrorMessage({ ...errorMessage, type: "warning", message: t("auth.noData"), open: true });
      setDisableButton(false);
      return;
    }


    let data = { name: name, surname: lastName, password: password, email: email, userType: userType, companyName: companyName, phone: phone, coinscrap: false, province: provincia, whiteLabel: whiteLabel };
    if (userType == "Representante empresarial") {
      data.originData = originData;
    }

    fetch(`${process.env.REACT_APP_REGISTER}?lang=${lng + "_" + lng.toUpperCase()}`, {
      method: "POST",
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        // Authorization: `Bearer ${UserService.getToken()}`,
      },
      body: JSON.stringify(data)
    })
      .then(j =>
        j.json()

      )

      .then(data => {

        setIdUser(data.id)
        setDisableButton(false);


        if (data !== null) {

          if (data.status === 500) {
            setErrorMessage({ ...errorMessage, type: "error", message: t("auth.emailExists"), open: true });
          } else {
            const error = data.message;
            if (error === "Error creating the user. Keycloak response status 409") {
              setErrorMessage({ ...errorMessage, type: "error", message: t("auth.emailExists"), open: true });
            } else if (error === "Account is not fully set up") {
              setErrorMessage({ ...errorMessage, type: "error", message: t("auth.noFullySetUp"), open: true });
            } else {
              setEmail("");
              setName("");
              setLastName("");
              setUserType("")
              setPassword("");
              setPhone("");
              setOriginData("");
              setcompanyName("");
              setWhiteLabel("");
              event.target.reset();
              setErrorMessage({ ...errorMessage, type: "success", message: t("auth.created"), open: true });

            }
          }
          return;
        }

      }
      ).catch(error => {
        setErrorMessage({ ...errorMessage, type: "error", message: t("auth.error"), open: true });
        setDisableButton(false)
      });

  }

  const Subscription = () => {

    const data = {
      email: email,
      name: name,
      id: idUser,
      lastName: lastName
    };

    fetch(`${process.env.REACT_APP_CH_CUSTOM_SUBSCRIPTION}/${whiteLabel}`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',

      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((responseJson) => {

      })
      .catch((error) => {
        console.error('Error en la solicitud:', error);
      });
  };

  useEffect(() => {


    if (idUser) {
      Subscription();
    }

  }, [idUser]);


  const [locations, setLocations] = useState({
    locationsData: [],
    isFetching: false,
  });

  useEffect(() => {
    const fetchLocations = async () => {
      try {
        setLocations({ locationsData: locations.locationsData, isFetching: true });
        await axios.get(`${process.env.REACT_APP_MP_LOCATIONS}`, {
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*'
          }
        }).then(response => {
          setLocations({ locationsData: response.data, isFetching: false });
          // setItems(response.data);
        });
      } catch (exception) {
        setLocations({ locationsData: locations.locationsData, isFetching: false });
      }
    };
    fetchLocations();
  }, []);

  const cleanMessage = () => {
    setErrorMessage({ ...errorMessage, type: "", message: "", open: false });
  }

  const logo = getLogo(storedParam)



  return (
    <div className={`min-h-screen h-full flex items-center justify-center ${getFont(storedParam)}`}>
      <Helmet>
        <meta name="robots" content=" noindex, nofollow" />
      </Helmet>
      <div style={{ maxWidth: '500px' }}>
        {logo &&
          <div className='flex justify-center'>
            <img src={logo} style={{ height: '150px' }} alt={`${whiteLabel} logo`}></img>
          </div>

        }



        {(errorMessage.type !== 'success') &&

          <h1 className={`text-3xl  mt-4 mb-6`}>{t("auth.titleRegister")} ✨</h1>
        }


        {(errorMessage.type === 'error') &&

          <Banner type={errorMessage.type} open setOpen={true}>{errorMessage.message}</Banner>

        }
        {/* Formulario */}
        {(errorMessage.type !== 'success') &&

          <form onSubmit={register} >

            <div className='flex flex-wrap NameLastname '>
              <div className='w-full sm:w-1/2 pr-1 mt-1 mb-1'>
                <label className="block text-sm font-medium mb-1" htmlFor="name">{t("auth.name")} <span className="text-red-500">*</span></label>
                <input id="name" className="form-user-data w-full bg-white" type="text" required defaultValue={name} onChange={(e) => { cleanMessage(); setName(e.target.value) }} />
              </div>
              <div className="w-full sm:w-1/2 pr-1 mt-1 mb-1">
                <label className="block text-sm font-medium mb-1" htmlFor="lastName">{t("auth.lastName")} <span className="text-red-500">*</span></label>
                <input id="lastName" className="form-user-data w-full bg-white" type="text" required defaultValue={lastName} onChange={(e) => { cleanMessage(); setLastName(e.target.value) }} />
              </div>
            </div>

            <div className='flex flex-wrap phoneEmail  '>
              <div className='w-full sm:w-1/2 pr-1 mt-1 mb-1 '>
                <label className="block text-sm font-medium mb-1" htmlFor="email">{t("auth.email")} <span className="text-red-500">*</span></label>
                <input id="email" className=" w-full bg-white form-user-data" type="email" defaultValue={email} required onChange={(e) => { cleanMessage(); setEmail(e.target.value) }} />
              </div>
              <div className='w-full sm:w-1/2 pr-1 mt-1 mb-1'>
                <label className="block text-sm font-medium mb-1" htmlFor="phone">{t("auth.phone")} <span className="text-red-500">*</span></label>
                <input id="phone" className=" w-full bg-white form-user-data" type="tel" pattern="[0-9]{7}|[0-9]{9}" defaultValue={phone} required onChange={(e) => { cleanMessage(); setPhone(e.target.value) }} />
              </div>
            </div>



            <div className="pr-1 mt-1 mb-1">

              <label className="block text-sm font-medium mb-1" htmlFor="usertype">{t("auth.escogePerfil")} <span className="text-red-500">*</span></label>

              <select required defaultValue={'Representante empresarial'} list="usertype" className="form-user-data w-full bg-white" id="usertype" name="usertype" onChange={(e) => { cleanMessage(); setUserType(e.target.value) }}>

                <option value={""} disabled="disabled">{t("auth.escoge")}...</option>"
                <option value="Representante empresarial">{t("auth.empresarial")}</option>
                <option value="Propietario Forestal">{t("auth.forestal")}</option>
              </select>
            </div>


            {(userType === 'Representante empresarial') &&
              <div className="pr-1 mt-1 mb-1 hidden">
                <label className="block text-sm font-medium mb-1" htmlFor="originData">{t("auth.originData")} <span className="text-red-500">*</span></label>
                <select required defaultValue={""} list="originData" className="form-user-data w-full bg-white" id="originData" name="originData" onChange={(e) => { cleanMessage(); setOriginData(e.target.value) }}>

                  <option value="Formulario">{t("auth.form")}</option>
                </select>
              </div>


            }


            {(userType === 'Representante empresarial') &&
              <div className='pr-1 mt-1 mb-1'>


                <label className="block text-sm font-medium mb-1" htmlFor="companyName">{t("auth.companyName")} <span className="text-red-500">*</span></label>
                <input id="companyName" className="form-user-data w-full bg-white" type="text" required defaultValue={companyName} onChange={(e) => { cleanMessage(); setcompanyName(e.target.value) }} />
              </div>


            }

            {(userType === 'Propietario Forestal') &&
              <div className="pr-1 mt-1 mb-1">
                <label className="block text-sm font-medium mb-1" htmlFor="provincia">{t("auth.provinciaMasaForestal")} <span className="text-red-500">*</span></label>
                <select required defaultValue={""} list="provincia" className="form-user-data w-full bg-white" id="provincia" name="provincia" onChange={(e) => { cleanMessage(); setProvincia(e.target.value) }}>
                  <option value={""} disabled="disabled">{t("auth.select_province")} </option>"
                  {locations.locationsData.length > 0 ?
                    locations.locationsData[0].regionList.map((v) => {
                      return (
                        <option key={v.id} value={v.name}>{v.name}</option>
                      )
                    }) : "Cargando..."}
                </select>
              </div>
            }



            <div className='flex flex-wrap '>
              <div className='w-full sm:w-1/2 pr-1 mt-1 mb-1'>
                <label className="block text-sm font-medium mb-1" htmlFor="password">{t("auth.password")} <span className="text-red-500">*</span></label>
                <input id="password" className="form-user-data w-full bg-white" name="password" type="password" required defaultValue={password} autoComplete="on" onChange={e => onInputPasswordChange(e)} onBlur={validateInput} />
                {passwordMessage !== "" && <p className="mt-2 text-xs text-red-600 dark:text-green-400"><span className="font-medium"> {passwordMessage} </span> </p>}
              </div>

              <div className='w-full sm:w-1/2 pr-1 mt-1 mb-1 '>
                <label className="block text-sm font-medium mb-1" htmlFor="password-repeat">{t("auth.passwordRepeat")} <span className="text-red-500">*</span></label>
                <input id="password-repeat" className="form-user-data w-full bg-white" name="confirmPassword" type="password" defaultValue={password} required autoComplete="off" onChange={e => onInputPasswordChange(e)} onBlur={validateInput} />
              </div>
            </div>


            <div>
              <label className="flex items-center font-medium">
                <input type="checkbox" className="form-checkbox" required />
                <span className="text-sm ml-2">{t("auth.conditions")} <a href="/terminos">{t("auth.terms")} </a><span className="text-red-500" >*</span></span>
              </label>
            </div>


            <div className=" mt-6">

              <button className={`btn  text-white  whitespace-nowrap w-full`} type="submit" disabled={disableButton} style={{ backgroundColor: getColor(whiteLabel) }}>

                {disableButton ? <svg role="status" className="inline mr-3 w-4 h-4 text-white animate-spin" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="#E5E7EB" />
                  <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentColor" />
                </svg> : t("auth.register")}
              </button>
            </div>
          </form>


        }


        {(errorMessage.type === 'success') &&
          <div style={{ width: '27rem' }}>
            <h1 className={`text-3xl font-bold mt-2 mb-2`}>{t("auth.titleRegister")} ✨</h1>
            <div className='flex flex-wrap  justify-center' >
              <h2 className={`my-2`}>
                {errorMessage.message}
              </h2>

              <button className={`btn text-white  whitespace-nowrap w-full mt-8`} style={{ backgroundColor: getColor(storedParam) }}>
                <a href={`${getUrl(whiteLabel)}`} className='nav-link text-white'>{t("auth.principal")}<span className='px-1'>{getName(storedParam)}</span></a>
              </button>


            </div>
          </div>



        }


        {/* Footer */}
        <div className="pt-5 mt-6 border-t border-gray-200">
          <div className={`text-sm text-${storedParam}-secondary  text-center`}>
            {t("auth.hasAccount")} <Link className={`font-medium text-${storedParam}-general`} to={`/login-external?param=${storedParam}`}>{t("auth.login")} </Link>
          </div>
        </div>
        <div className='flex justify-center mt-4'>
          <img className='' src={logoairco2} style={{ height: '25px' }}></img>

        </div>


      </div>

    </div>
  )
}