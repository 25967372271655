import React, { useState } from 'react';
import axios from 'axios';

const FileUploader = ({ uploadFiles }) => {
  const [files, setFiles] = useState([]);
  const [error, setError] = useState('');
  const [isDragging, setIsDragging] = useState(false); // Nuevo estado para controlar el dragover

  const MAX_FILE_SIZE_MB = 25;
  const MAX_FILE_SIZE_BYTES = MAX_FILE_SIZE_MB * 1024 * 1024;

  const handleFiles = (selectedFiles) => {
    const validFiles = Array.from(selectedFiles).filter(file => {
      if (file.size > MAX_FILE_SIZE_BYTES) {
        setError(`El archivo "${file.name}" excede el tamaño máximo de 25MB`);
        return false;
      }
      return true;
    });

    if (validFiles.length > 0) {
      setError('');
      uploadFiles(validFiles);
    }
  };

  const handleDragOver = (event) => {
    event.preventDefault();
    setIsDragging(true);
  };

  const handleDragLeave = () => {
    setIsDragging(false);
  };

  const handleDrop = (event) => {
    event.preventDefault();
    setIsDragging(false);
    const droppedFiles = event.dataTransfer.files;
    handleFiles(droppedFiles);
  };

  const handleFileChange = (event) => {
    const selectedFiles = event.target.files;
    handleFiles(selectedFiles);
  };

  const handleDivClick = () => {
    document.getElementById('fileInput').click();
  };



  return (
    <div className='h-full'>
      <div
        id="dropzone"
        onClick={handleDivClick}
        onDragOver={handleDragOver}
        onDragLeave={handleDragLeave}
        onDrop={handleDrop}
        style={{
          width: '100%',
          height: '100%',
          padding: '4px',
          border: `2px dashed ${isDragging ? 'var(--airco2-orange-color)' : 'var(--airco2-grey-color)'}`,
          borderRadius: '10px',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          textAlign: 'center',
          color: `{${isDragging ? 'var(--airco2-orange-color)' : 'var(--airco2-grey-color)'}`,
          fontSize: '16px',
          cursor: 'pointer',
          backgroundColor: isDragging && '#f9f9f9',
        }}
      >
        <span> {isDragging ? 'Suelta tus archivos' : 'Arrastra tus archivos o haz clic aquí para subirlos'} </span>
        <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-upload" width="24" height="24" viewBox="0 0 24 24" strokeWidth='1.5' stroke='var(--airco2-grey-color)' fill="none" strokeLinecap="round" strokeLinejoin="round">
             <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
             <path d="M4 17v2a2 2 0 0 0 2 2h12a2 2 0 0 0 2 -2v-2" />
             <path d="M7 9l5 -5l5 5" />
             <path d="M12 4l0 12" />
         </svg>
      </div>
      <input
        type="file"
        id="fileInput"
        multiple
        onChange={handleFileChange}
        style={{ display: 'none' }}
      />

      {error && <p style={{ color: 'red' }}>{error}</p>}

    </div>
  );
};

export default FileUploader;
