import React, { useState, useContext, useEffect } from 'react';
import Sidebar from '../Sidebar';
import Header from '../Header';
import { IdiomContext } from "../../../context/createcontext"
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { Col, Modal, Row, Form } from 'react-bootstrap';
import UserService from '../../../services/UserService';
import Spinner from 'react-bootstrap/Spinner';
import { useNavigate } from 'react-router-dom';
import Translations from '../../../services/Translations';
import { formatThousands } from '../utils/Utils';



export default function MisCertificaciones() {

    const [sidebarOpen, setSidebarOpen] = useState(false);
    const [lng, updateLng] = useContext(IdiomContext)
    const [t, i18n] = useTranslation("global");
    const navigate = useNavigate();

    const [certifications, setCertifications] = useState({
        data: [],
        isFetching: false
    });

    useEffect(() => {
        const fectCertifications = async () => {
            setCertifications({ data: certifications.data, isFetching: true });
            try {

                const response = await axios.get(`${process.env.REACT_APP_CH_CERTIFICATIONS}?userId=${UserService.getUserId()}`, {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        // 'Access-Control-Allow-Origin': '*',
                        'Authorization': `Bearer ${UserService.getToken()}`
                    }
                });
                setCertifications({ data: response.data, isFetching: false });

            } catch (exception) {
                setCertifications({ data: [], isFetching: false });
            }
        };
        fectCertifications();
    }, [])


    const statusColor = (props) => {
        switch (props) {
            case "CREATED":
                return "#b4e1ef";
            case "DOCUMENTATION":
                return "#b4e1ef";
            case "COMPLETED":
                return "#97cd9f";
            case "SENT":
                return "#cfbd5e";
            case "ACEPTED":
                return "#e3b199";
        }
    }


    return (

        <div className={`sec-neutro-background flex h-screen overflow-hidden`}>

            {/* Sidebar */}
            <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

            {/* Content area */}
            <div className="sec-neutro-background relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">

                {/*  Site header */}
                <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

                <main>
                    <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
                        {/* Page header */}
                        <div className="mb-8">
                            {/* Title */}
                            <h1 className="text-2xl md:text-3xl text-gray-800 font-bold">Mis certificaciones</h1>

                            <div className='flex justify-end'>
                                <button className='btn-banner-o rounded-lg px-4 py-2' onClick={() => navigate('/footprint/certifications/miteco')} >
                                    Nueva certificación
                                </button>
                            </div>
                        </div>


                        {certifications.isFetching ? (
                            <div className='flex justify-center'>
                                <Spinner className='mt-4' animation="border" variant="dark" />
                            </div>

                        ) : (
                            (certifications?.data?.data?.length !== undefined && certifications?.data?.data?.length !== 0) && (

                                <div className="container-simple-table w-full">
                                    <table className="simple-table">
                                        <thead className='text-center  text-xl sec-green-background text-white'>
                                            <tr className='py-4'>
                                                <th>
                                                    Tipo de certificación
                                                </th>
                                                <th>
                                                    {t("footPrint.organizacion")}
                                                </th>
                                                <th>
                                                    {t("footPrint.year")}
                                                </th>
                                                <th>
                                                    {t("footPrint.estado")}
                                                </th>
                                                <th>
                                                    Tipo de cálculo
                                                </th>
                                                <th className='text-end pr-5' />
                                            </tr>
                                        </thead>
                                        <tbody className='text-center'>
                                            {certifications.data.length > 0 ? <tr className='flex justify-center'> <Spinner animation="border" variant="dark" /> </tr> :
                                                certifications.data.data != undefined && certifications.data.data.map((certification) => {

                                                    return (
                                                        <tr key={certification.id}>
                                                            <td>{certification.certificationType}</td>
                                                            <td>{certification.organizationName}</td>
                                                            <td>{certification.calculationYear}</td>
                                                            <td>
                                                                <div className='rounded-full' style={{ backgroundColor: statusColor(certification.certificationMitecoStatus) }}>
                                                                    {Translations.transalteStatusCertifications(certification.certificationMitecoStatus)}
                                                                </div>
                                                            </td>
                                                            <td>{certification.questionnaireName}</td>
                                                            <td>
                                                                <span className="airco2-a-tag" onClick={()=>navigate(`/footprint/certification/${certification.id}`)}>Ver certificación</span>
                                                                </td>
                                                        </tr>
                                                    )
                                                })
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            ))
                        }
                    </div>

                </main>
            </div>
        </div>
    )
}