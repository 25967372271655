import React, { useContext } from 'react'

import { Swiper, SwiperSlide } from 'swiper/swiper-react.mjs';
import { Navigation, Pagination, Autoplay } from 'swiper/modules';
import { IdiomContext } from "../../../context/createcontext"
import { useTranslation } from 'react-i18next';
import { useNavigate, useLocation } from 'react-router-dom';

import 'swiper/swiper-bundle.min.css';
import 'swiper/swiper-bundle.css';
import 'swiper/swiper.css';
import 'swiper/modules/a11y.css'
import 'swiper/modules/pagination.css';
import 'swiper/modules/scrollbar.css';
import 'swiper/swiper.scss'; // core Swiper
import 'swiper/modules/navigation.scss'; // Navigation module
import 'swiper/modules/pagination.scss'; // Pagination module

import Carousel01 from '../../../images/success_stories_beflamboyant.webp'
import Carousel02 from '../../../images/success_stories_coolfarming.webp'
import Carousel03 from '../../../images/success_stories_perez_torres.webp'
import Carousel04 from '../../../images/success_stories_ontime.webp'

import Avatar01 from '../../../images/user_avatar.png'
import Avatar02 from '../../../images/user_avatar.png'
import Avatar03 from '../../../images/user_avatar.png'
import Avatar04 from '../../../images/user_avatar.png'

import ptmar from '../../../images/pereztorres.png';
import ontime from '../../../images/ontime.png';
import coolberries from '../../../images/coolfarming2.png';
import befla from '../../../images/befla.png';

export default function SuccessStories() {

    const [lng, updateLng] = useContext(IdiomContext);
    const [t, i18n] = useTranslation("global");
    const navigate = useNavigate();
    const location = useLocation();

    const slidesData = [
        {
            image: Carousel01,
            avatars: [Avatar01, Avatar02, Avatar03, Avatar04],
            title: ' Beflamboyant',
            collections: t("newHome.successStories-Beflamboyant"),
            logo: befla
        },
        {
            image: Carousel02,
            avatars: [Avatar01, Avatar02, Avatar03, Avatar04],
            title: 'CoolFarming',
            collections: t("newHome.successStories-CoolFarming"),
            logo: coolberries,
            clientPage: "/clientes/cool-berries"
        }, {
            image: Carousel03,
            avatars: [Avatar01, Avatar02, Avatar03, Avatar04],
            title: 'Pérez Torres Marítima',
            collections: t("newHome.successStories-PerezTorres"),
            logo: ptmar,
            clientPage: "/clientes/perez-torres-maritima"
        }, {
            image: Carousel04,
            avatars: [Avatar01, Avatar02, Avatar03, Avatar04],
            title: 'Ontime',
            collections: t("newHome.successStories-Ontime"),
            logo: ontime
        }
    ];

    const handleClick = (props) => {
        navigate(props);
                window.scrollTo({
                    top: 0,
                    behavior: 'smooth'
                });
    };

    return (
        <section className="bg-gray-50">
            <div className="max-w-6xl mx-auto px-4 sm:px-6" data-aos="fade-up">
                <div className="py-12 md:py-20">
                    {/* Section header */}
                    <div className="max-w-3xl mx-auto text-center pb-12">
                        <h2 className="h2 font-cabinet-grotesk text-airco2-principal">{t("newHome.successStoriesTitle")}</h2>
                    </div>

                    <div data-aos="flip-left">
                        <Swiper
                            breakpoints={{
                                320: {
                                    slidesPerView: 1,
                                },
                                640: {
                                    slidesPerView: 2,
                                },
                                1024: {
                                    slidesPerView: 4,
                                },
                            }}
                            modules={[Navigation, Pagination, Autoplay]}
                            grabCursor={true}
                            loop={false}
                            centeredSlides={false}
                            initialSlide={0}
                            spaceBetween={24}
                            pagination={{ clickable: true }}

                            // navigation
                            className="carousel swiper-container max-w-sm mx-auto sm:max-w-none "
                        >

                            {/* Carousel items */}
                            {slidesData.map((slide, index) => (
                                <SwiperSlide key={index} className={`swiper-slide h-auto flex flex-col relative success-slide ${slide.clientPage && "card-client-detail"}`} onClick={() => slide.clientPage != undefined && handleClick(slide.clientPage)}>
                                    {/* Image */}
                                    <div className="relative">
                                        <img className="w-full h-48 object-cover" src={slide.image} alt={`Cliente ${index + 1}`} />
                                        <div className='bg-white absolute px-1 shadow' style={{ width: '85px', height: '55px', top: '5px', right: '5px', borderRadius: '1rem' }} >
                                            <div className='w-full h-full' style={{ backgroundImage: `url(${slide.logo})`, backgroundRepeat: 'no-repeat', backgroundPosition: 'center', backgroundSize: 'contain' }} />
                                        </div>
                                    </div>
                                    {/* White box */}
                                    <div className="bg-white p-3 pb-4 swiper-body text-airco2-general-grey">
                                        {/* Title */}
                                        <span className={`block font-bold text-lg py-2 `}>{slide.title}</span>
                                        <div className="text-base	">{slide.collections}</div>
                                    </div>
                                </SwiperSlide>
                            ))}






                        </Swiper>
                    </div>
                </div>
            </div>
        </section>
    )
}