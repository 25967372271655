import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { IdiomContext } from "../../context/createcontext";
import slide from '../../images/slide_02.jpg';
import UserService from '../../services/UserService';

function HeroHome() {

  const [lng, updateLng] = useContext(IdiomContext)
  const [t, i18n] = useTranslation("global");
  // const url = `${UserService.doRegistration()}&ui_locales=${lng}`
  const url = `/register`

  return (
    <section className="relative sec-green-background" style={{ paddingTop: '100px' }}>


      <div className="max-w-6xl mx-auto px-4 sm:px-6">
        {/* Hero content */}
        {/* Section header */}
        <div className="text-center pb-8">
          <h1 className="text48 sec-blue-text leading-tighter tracking-tighter mb-4" data-aos="zoom-y-out" >
            {t("forestitle.propietariosmundo")}
            <span className="bg-clip-text text-transparent text-white text48" > {t("forestitle.unios")}</span></h1>
          <div className="px-2 ">
            <a className='btn btn-banner-o' href={url}>{t("forestitle.quiero")}</a>
          </div>
          {/*video */}
          {/* <div  data-aos="zoom-y-out">       
            <img className='hidden md:flex md:grow' src={videogif} alt="loading..." width='100%'></img>

          </div> */}
        </div>
      </div>

      <div className="" data-aos="fade-down" >
        <div className="pb-12">
          <div className="relative w-full pb-3/4" style={{ backgroundImage: "url(" + slide + ")", height: "60vh", backgroundSize: "cover" }}>
            {/* <img alt='foto manos' src={videogif}></img> */}
            {/* <img className='hidden md:flex md:grow w-full' src={videogif} alt="loading..." width='100%'></img> */}
            <div className="  flex w-full h-full justify-center	items-center	 ">
              <p className="max-w-3xl text18 sec-white-text mb-2 text-center text-banner-style" data-aos="zoom-y-out" data-aos-delay="150">{t("forestitle.arbolesservicio")}</p>
            </div>
          </div>
        </div>
      </div>

    </section>
  );
}

export default HeroHome;